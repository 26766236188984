import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthenticationResult } from '@azure/msal-browser';
import { base64StringToBlob } from 'blob-util';
import { MsalService } from '@azure/msal-angular';

import { AnnotationDataFormat } from '@syncfusion/ej2-angular-pdfviewer';

import { AppConstants } from 'src/app/app.constants';
import { SignalRGroups } from 'src/app/app.signalr-group.constants';

import { BookmarkPageInfo } from 'src/app/models/bookmark-page-info.model';
import { ContainerDetail } from 'src/app/models/container-detail.model';
import { DialogOptions } from 'src/app/models/dialog-options.model';
import { Fact } from 'src/app/models/fact.model';
import { GetPageDetailRequest } from 'src/app/models/get-page-detail-request.model';
import { GetPageDetailResponse } from 'src/app/models/get-page-detail-response.model';
import { JobInformation } from 'src/app/models/job-information.model';
import { PageDto } from 'src/app/models/page-dto.model';
import { Rect } from 'src/app/models/rect.model';
import { ResetPageInfo } from 'src/app/models/reset-page-info.model';

import { FeatureType } from 'src/app/core/enums/feature-type.enum';
import { FrameType } from 'src/app/core/enums/frame-type.enum';
import { LanguageType } from 'src/app/core/enums/language-type.enum';
import { PageStatus } from 'src/app/core/enums/page-status.enum';

import { AppDataService } from 'src/app/services/app-data.service';
import { CommonService } from 'src/app/services/common.service';
import { DialogService } from 'src/app/services/dialog.service';
import { FileCompareService } from 'src/app/services/file-compare.service';
import { LogService } from 'src/app/services/log.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PdfViewerService } from 'src/app/services/pdf-viewer.service';
import { SignalRService } from 'src/app/services/signalr.service';

import { AlertDialogComponent as AlertDialog } from 'src/app/shared/alert-dialog/alert-dialog.component';
import { CanvasComponent } from './canvas/canvas.component';
import { ConfirmDialogComponent as ConfirmDialog } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { GoToPageComponent } from './go-to-page/go-to-page.component';
import { PDFViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { UserType } from 'src/app/core/enums/user-type.enum';

declare var $: any;

@Component({
  selector: 'app-file-compare',
  templateUrl: './file-compare.component.html',
  styleUrls: ['./file-compare.component.css'],
})
export class FileCompareComponent implements OnInit, OnDestroy {
  @ViewChild('leftPdfViewer') leftPdfViewer!: PDFViewerComponent;
  @ViewChild('leftCanvas') leftCanvas!: CanvasComponent;
  @ViewChild('rightCanvas') rightCanvas!: CanvasComponent;

  public readonly appConstants: typeof AppConstants = AppConstants;
  public readonly featureType = FeatureType;

  public readonly regionToggleControl: FormControl = new FormControl(true);

  constructor(
    private readonly appDataService: AppDataService,
    private readonly commonService: CommonService,
    private readonly dialogService: DialogService,
    private readonly msalService: MsalService,
    private readonly notificationService: NotificationService,
    private readonly pdfViewerService: PdfViewerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly signalRService: SignalRService,
    public fileCompareService: FileCompareService
  ) {}

  public leftContainer!: ContainerDetail;
  public rightContainer!: ContainerDetail;

  public jobIdentity!: string;
  public id!: any;
  public hasSamePageCount!: boolean;
  public showRegionCanvas = false;
  public frameType = FrameType;
  public isIgnoreRegionSelected: boolean = true;
  public isMonolingualSearch: boolean = false;
  public fileTypeId!: number;
  public jobInformation!: JobInformation;
  public isCallRedaction = false;
  public differentRedactedPages: string[] = [];
  public differentSquareBracketPages: string[] = [];
  public isChineseCharactersConversionCheck = false;
  public companySpecificGlossaryPages: string[] = [];
  public leftAnnotationClicked = false;
  public rightAnnotationClicked = false;
  public leftDocument: string = '';
  public rightDocument: string = '';
  public pageIdentity!: number;
  public accessToken!: string;
  public isSquareBracketSingleFile: boolean = false;
  public isSquareBracketCheckType!: boolean;
  public isFinancialCheckType: boolean = false;
  public loadFinancialCheckViewer: boolean = false;
  public isPageLocked: boolean = false;
  public isJobLocked: boolean = false;
  public isViewSourcePdfDisabled: boolean = false;
  private savedFactLists: Fact[] = [];

  private rotatePageDialog!: MatDialogRef<any>;
  private leftPageLoaded: boolean = false;
  private rightPageLoaded: boolean = false;
  private pageConversionEnglishPageNumber!: number;
  private pageConversionChinesePageNumber!: number;
  private autoNavigatePageInSignalR: boolean = false;
  public isProgressBarShown: boolean = false;
  public compareRegionControl: FormControl = new FormControl(!this.isIgnoreRegionSelected);
  public ignoreRegionControl: FormControl = new FormControl(this.isIgnoreRegionSelected);
  public syncScrollingModeControl: FormControl = new FormControl(false);

  ngOnInit() {
    this.getAccessToken();
    let englishFileId: number;
    let chineseFileId: number;
    this.id = window.history.state;
    this.route.queryParams.subscribe((params) => {
      this.jobIdentity = params['jobId'];
      englishFileId = +params['fileId1'];
      chineseFileId = +params['fileId2'];
      this.fileTypeId = +params['typeId'];

      if (+params['typeId'] === FeatureType.Square_Bracket_Check) {
        this.isSquareBracketCheckType = true;
        this.isSquareBracketSingleFile = Boolean(Number.isNaN(chineseFileId));
      }
      this.isFinancialCheckType = this.fileTypeId === FeatureType.Financial_Table_Check;
      this.leftContainer = this.initiateContainer('left-doc', englishFileId, LanguageType.English);
      if (this.fileTypeId === FeatureType.Chinese_Characters_Conversion_Check) {
        this.rightContainer = this.initiateContainer('right-doc', 0, LanguageType.Unknown);
        this.isMonolingualSearch = true;
      } else if (
        this.fileTypeId === FeatureType.Financial_Table_Check ||
        (this.isSquareBracketSingleFile && this.fileTypeId === FeatureType.Square_Bracket_Check)
      ) {
        this.rightContainer = this.initiateContainer('right-doc', 0, LanguageType.Unknown);
      } else {
        this.rightContainer = this.initiateContainer('right-doc', chineseFileId, LanguageType.Chinese);
      }
    });
    this.getJobCompareDetails();
    this.joinSignalRGroup();
    this.subscribeSignalRMessages();
  }

  private joinSignalRGroup(): void {
    if (this.appDataService.user.userObjectId!) {
      this.signalRService.joinGroup(SignalRGroups.MultiplePageDetailsUpdate(this.appDataService.user.userObjectId));
    }
  }

  private subscribeSignalRMessages(): void {
    this.signalRService.hubConnection.on(AppConstants.SignalRMethods.broadcastMessage, (signalrData: any) => {
      console.log(signalrData);
      if (this.isLeftContainerId(signalrData.message)) {
        if (!this.leftPageLoaded) {
          this.navigateToPage(
            this.leftContainer,
            null,
            this.pageConversionEnglishPageNumber,
            0,
            true,
            true,
            this.autoNavigatePageInSignalR
          );
        }
      } else if (!this.rightPageLoaded) {
        this.navigateToPage(null, this.rightContainer, 0, this.pageConversionChinesePageNumber, true, true, this.autoNavigatePageInSignalR);
      }
    });
  }

  private getAccessToken() {
    const accessTokenRequest: any = { scopes: [(window as any)[AppConstants.AppSettings].azure.ad.scope] };
    this.msalService.acquireTokenSilent(accessTokenRequest).subscribe({
      next: (accessTokenResponse: AuthenticationResult): void => {
        this.accessToken = `Bearer ${accessTokenResponse.accessToken}`;
      },
      error: (error) => {
        LogService.Debug(error);
      },
    });
  }

  ngOnDestroy() {
    this.leftContainer.pageLoaded = true;
    this.rightContainer.pageLoaded = true;
    this.signalRService.hubConnection.off(AppConstants.SignalRMethods.broadcastMessage);
  }

  private initiateContainer(id: string, fileId: number, languageType: LanguageType): ContainerDetail {
    return {
      id,
      url: null,
      fileId,
      frameElement: $(`#${id}`),
      languageType,
      factList: [],
      factListHistory: [],
      currentPageNumber: 0,
      currentPageStatus: 0,
      currentPageIdentity: 0,
      pageCount: 0,
      pages: [],
      conversionRequestedPages: [],
      pageLoaded: false,
      pageLoadFailed: false,
      isBookmarked: false,
      isDirty: false,
      unSavedDocumentStyle: '',
      fileRegions: [],
      tmpFileRegions: [],
      currentPageRegions: [],
      tmpCurrentPageRegions: [],
      iFrameWidth: 0,
      iFrameHeight: 0,
      pdfFilePath: '',
    };
  }

  private getJobCompareDetails() {
    this.showLoader(true);
    this.fileCompareService.GetJobCompareDetails(this.jobIdentity).subscribe({
      next: (res) => {
        if (res) {
          // job information
          this.jobInformation = res.jobInformation;
          this.isJobLocked = res.jobInformation.isLocked;
          this.isViewSourcePdfDisabled = this.commonService.isFileNameDisabled(res.jobInformation.userType);

          if (this.jobInformation.typeId === FeatureType.Call_Redaction) {
            this.isCallRedaction = true;
            if (this.jobInformation.redactedDifferentCountPages != null && this.jobInformation.redactedDifferentCountPages !== '') {
              this.differentRedactedPages = this.jobInformation.redactedDifferentCountPages.split(',');
            }
          }
          if (this.jobInformation.typeId === FeatureType.Square_Bracket_Check) {
            if (
              this.jobInformation.squareBracketDifferentCountPages != null &&
              this.jobInformation.squareBracketDifferentCountPages !== ''
            ) {
              this.differentSquareBracketPages = this.jobInformation.squareBracketDifferentCountPages
                ? this.jobInformation.squareBracketDifferentCountPages.split(',')
                : [];
            }
          }
          if (this.jobInformation.typeId === FeatureType.Chinese_Characters_Conversion_Check) {
            this.isChineseCharactersConversionCheck = true;
            if (this.jobInformation.companySpecificGlossaryPages != null && this.jobInformation.companySpecificGlossaryPages !== '') {
              this.companySpecificGlossaryPages = this.jobInformation.companySpecificGlossaryPages.split(',');
            }
          }

          // ignored regions
          this.leftContainer.fileRegions = res.englishIgnoredRegions ? JSON.parse(res.englishIgnoredRegions) : [];
          this.rightContainer.fileRegions = res.chineseIgnoredRegions ? JSON.parse(res.chineseIgnoredRegions) : [];

          // pages data
          this.leftContainer.pages = this.getPagesByLanguageType(res.comparePages, LanguageType.English);
          this.rightContainer.pages = this.getPagesByLanguageType(res.comparePages, LanguageType.Chinese);

          // get pages count
          this.leftContainer.pageCount = this.leftContainer.pages.length;
          this.rightContainer.pageCount = this.rightContainer.pages.length;
          // hasSamePageCount
          this.hasSamePageCount = this.leftContainer.pageCount === this.rightContainer.pageCount;
          this.syncScrollingModeControl.setValue(this.hasSamePageCount);
          // set page loaded = false
          this.leftContainer.pageLoaded = false; // should be set to false prior initiating load page
          this.rightContainer.pageLoaded = false; // should be set to false prior initiating load page
          this.leftContainer.pageLoadFailed = false;
          this.rightContainer.pageLoadFailed = false;

          const p1 = new Promise((resolve, reject) => {
            this.loadPage(this.leftContainer, 1, resolve, reject);
          });
          let p2: Promise<boolean>;
          if (!this.isMonolingualSearch && !this.isSquareBracketSingleFile) {
            p2 = new Promise((resolve, reject) => {
              this.loadPage(this.rightContainer, 1, resolve, reject);
            });
          } else {
            p2 = Promise.resolve(true);
          }

          Promise.all([p1, p2])
            .then(([result1, result2]) => {
              if (result1 && result2) {
                if (this.areBothContainerUnLocked()) {
                  if (
                    this.hasDifferentPages(this.leftContainer.currentPageNumber) ||
                    this.hasDifferentPages(this.rightContainer.currentPageNumber)
                  ) {
                    return this.selectFirstElement();
                  }
                  if (this.jobInformation.squareBracketDifferentCountPages === null && this.isSquareBracketCheckType) {
                    this.dialogService.open(AlertDialog, AppConstants.NoSquareBracketCountPagesAlert);
                    return;
                  }
                  this.navigateNext(this.leftContainer, this.rightContainer, false);
                } else {
                  this.selectFirstElement();
                }
              }
            })
            .catch((error) => {
              this.notificationService.error(AppConstants.ComparePageStatus, error);
            });
        }
        this.showLoader(false);
      },
      error: (err) => {
        if (err === AppConstants.ErrorSessionTimeout || err?.status === 401) {
          this.notificationService.error(AppConstants.ErrorSessionTimeoutTitle, AppConstants.ErrorSessionTimeout);
        } else {
          this.notificationService.error('', 'An unexpected Error has occurred.');
        }
        this.showLoader(false);
      },
    });
  }

  private hasDifferentPages(pageNumber: number) {
    return (
      (this.jobInformation.typeId === FeatureType.Call_Redaction &&
        !(this.differentRedactedPages.length === 0 ? true : this.differentRedactedPages.some((x) => +x === pageNumber))) ||
      (this.jobInformation.typeId === FeatureType.Square_Bracket_Check &&
        !(this.differentSquareBracketPages.length === 0 ? true : this.differentSquareBracketPages.some((x) => +x === pageNumber))) ||
      (this.jobInformation.typeId === FeatureType.Chinese_Characters_Conversion_Check &&
        !(this.companySpecificGlossaryPages.length === 0 ? true : this.companySpecificGlossaryPages.some((x) => +x === pageNumber)))
    );
  }

  private skipDirtyCheck() {
    return (
      (this.leftContainer.factList.length == 0 ? false : this.hasDifferentPages(this.leftContainer.currentPageNumber)) ||
      (!this.isSquareBracketSingleFile &&
        this.rightContainer != null &&
        (this.rightContainer.factList.length == 0 ? false : this.hasDifferentPages(this.rightContainer.currentPageNumber)))
    );
  }

  private isFinancialCheckSaved() {
    return this.isFinancialCheckType && this.leftPdfViewer.leftPdfViewer.annotationCollection.length > 0 && this.leftContainer.isDirty;
  }

  private skipDirtyOnSyncMode(container1: ContainerDetail, container2: ContainerDetail | null) {
    return (
      this.syncScrollingModeControl.value &&
      ((container1.factList.length === 0 && container1.factListHistory.length === 0) ||
        (container2 && container2.factList.length === 0 && container2.factListHistory.length === 0))
    );
  }

  private selectFirstElement() {
    this.unSelectSwappedElement(this.leftContainer);
    this.unSelectSwappedElement(this.rightContainer);
    this.scrollAndSelectFirstElement(this.leftContainer);
    this.scrollAndSelectFirstElement(this.rightContainer);
  }

  private getPagesByLanguageType(data: PageDto[], languageType: LanguageType): PageDto[] {
    return data.filter((x) => x.languageId === languageType).sort((a, b) => a.pageSequence - b.pageSequence);
  }

  waitForIframeLoad(container: ContainerDetail) {
    return new Promise((resolve: (value: boolean) => void, reject: (reason: any) => void) => {
      const waitForPageLoadStatus = (ctr: ContainerDetail, count = 0) => {
        if (ctr.pageLoaded) {
          resolve(true);
        } else if (ctr.pageLoadFailed) {
          resolve(false);
        } else {
          setTimeout(() => {
            count++;
            if (count < 100) {
              waitForPageLoadStatus(ctr, count);
            } else {
              reject('page not loaded within timeframe. Rejecting the request.');
            }
          }, 1000);
        }
      };
      waitForPageLoadStatus(container);
    });
  }

  public onIframeLoad(container: ContainerDetail): void {
    if (container && container.url) {
      container.factList.length = 0;
      container.factListHistory.length = 0;
      container.factList = this.savedFactList(container, 'fact:not(.accepted,.rejected,.glossary-accepted,.glossary-rejected,.ignored)');
      this.savedFactLists = this.savedFactList(container, 'fact:is(.accepted,.rejected,.glossary-accepted,.glossary-rejected,.ignored)');
      if (container.factList.length > 0) {
        this.sortFactList(container.factList);
        if (container.currentPageRegions.length > 0) {
          this.ignoreFacts(container.factList, container.currentPageRegions, container.id!);
        } else if (container.fileRegions.length > 0) {
          this.ignoreFacts(container.factList, container.fileRegions, container.id!);
        }
      } else if (container.currentPageStatus !== PageStatus.Completed) {
        container.isDirty = true;
      }
      container.pageLoaded = true;
    }
  }

  public savedFactList(container: ContainerDetail, findText: string): Fact[] {
    let arr: Fact[] = [];
    const documentContainer = $(container.frameElement).contents().find('html');
    if ($(documentContainer).find('div').length === 0) {
      return [];
    }
    $.each($(documentContainer).find(findText), (i: any, e: any) => {
      arr.push({
        left: $(e).offset().left,
        top: $(e).offset().top,
        width: e.getBoundingClientRect().width,
        height: e.getBoundingClientRect().height,
        value: $(e).attr('value'),
        casesensitive: !!$(e).attr('casesensitive'),
        isglossary: !!$(e).attr('isglossary'),
        element: e,
        canBeIgnored: false,
      });
    });
    return arr;
  }

  private ignoreFacts(factList: Fact[], regions: Rect[], containerSide: string): void {
    const ignoredRegions = regions.filter((x) => x.isIgnore === undefined || x.isIgnore);
    const compareRegions = regions.filter((x) => x.isIgnore !== undefined && !x.isIgnore);

    // ignore facts which exists in ignore region
    // Rules to ignore a fact:
    // 1. left should be between x1 and x2
    // 2. left + width should be <= x2
    // 3. top should be between y1 and y2
    if (ignoredRegions.length > 0) {
      let index = factList.length - 1;
      while (index >= 0) {
        const fact = factList[index];
        let spliceList = false;
        ignoredRegions.forEach((region) => {
          // ignore region
          if (fact.left >= region.x1 && fact.left <= region.x2) {
            // condition 1
            if (fact.left + fact.width <= region.x2) {
              // condition 2
              if (fact.top >= region.y1 && fact.top <= region.y2) {
                // condition 3
                this.markElementAsIgnored(fact.element);
                spliceList = true;
              }
            }
          }
        });
        if (spliceList) {
          const ignoredFacts = factList.splice(index, 1);
          this.isLeftContainerId(containerSide)
            ? this.leftContainer.factListHistory.push(...ignoredFacts)
            : this.rightContainer.factListHistory.push(...ignoredFacts);
        }

        if (factList.length === 0) {
          if (this.isLeftContainerId(containerSide)) {
            this.leftContainer.isDirty = true;
          } else {
            this.rightContainer.isDirty = true;
          }
        }
        index -= 1;
      }
    }

    // ignore facts which does not exists in compare region
    // Rules to compare a fact:
    // 1. left should be between x1 and x2
    // 2. left + width should be <= x2
    // 3. top should be between y1 and y2
    if (compareRegions.length > 0) {
      let index = factList.length - 1;
      while (index >= 0) {
        const fact = factList[index];
        fact.canBeIgnored = true;
        compareRegions.forEach((region) => {
          // ignore region
          if (fact.left >= region.x1 && fact.left <= region.x2) {
            // condition 1
            if (fact.left + fact.width <= region.x2) {
              // condition 2
              if (fact.top >= region.y1 && fact.top <= region.y2) {
                // condition 3
                fact.canBeIgnored = false;
              }
            }
          }
        });
        index -= 1;
      }
      index = factList.length - 1;
      while (index >= 0) {
        const fact = factList[index];
        if (fact.canBeIgnored) {
          this.markElementAsIgnored(fact.element);
          const ignoredFacts = factList.splice(index, 1);
          this.isLeftContainerId(containerSide)
            ? this.leftContainer.factListHistory.push(...ignoredFacts)
            : this.rightContainer.factListHistory.push(...ignoredFacts);
        }
        index -= 1;
      }
    }
  }

  private loadPage(
    container: ContainerDetail,
    pageNumber: number,
    resolve: (value: boolean) => void,
    reject: (reason?: any) => void,
    isGoToPageRequest: boolean = false,
    skipPageConversionLoader: boolean = true,
    isAutoNavigationEnable: boolean = false
  ): void {
    const page: PageDto | undefined = container.pages.find((x) => x.pageSequence === pageNumber);
    if (page) {
      // if financial table check, we just need to load the PDF page.
      if (this.isFinancialCheckType) {
        container.url = page.fileName;
        container.currentPageNumber = pageNumber;
        container.currentPageStatus = page.pageStatus;
        container.currentPageIdentity = page.pageIdentity;
        container.isBookmarked = page.isBookmarked;
        container.pdfFilePath = page.pdfFilePath;
        this.leftDocument = this.leftContainer.pdfFilePath;
        this.pageIdentity = this.leftContainer.currentPageIdentity;
        resolve(true);
        this.loadFinancialCheckViewer = true;
        this.isPageLocked = this.isPageStatusLocked(page.pageStatus);
        return;
      }

      const pageContent = page.content;
      if (!pageContent) {
        // get the pages
        const pageIds: number[] = container.pages.filter((x) => x.pageSequence === pageNumber).map((p1) => p1.pageIdentity);
        if (pageIds.length > 0) {
          const getPageDetailRequest: GetPageDetailRequest = {
            jobTypeId: this.fileTypeId,
            fileId: container.fileId,
            pageIds,
            currentPageNumber: pageNumber,
            jobIdentity: this.jobIdentity,
            viewerSide: container.id!,
          };
          if (!skipPageConversionLoader) {
            this.showLoader(true);
          }
          this.fileCompareService.GetPageDetails(getPageDetailRequest).subscribe({
            next: (data: GetPageDetailResponse) => {
              this.isJobLocked = data.isLocked;
              if (data.isLocked) {
                this.notificationService.error(AppConstants.ErrorJobLockedTitle, AppConstants.ErrorJobLockedMessage);
              }
              const pages: PageDto[] = data.comparePages;
              if (pages.length > 0) {
                pages.forEach((p2) => {
                  const index = container.pages.findIndex((x) => x.pageIdentity === p2.pageIdentity);
                  if (index > -1) {
                    container.pages[index] = p2;
                  }
                });
                const p = container.pages.find((x) => x.pageSequence === pageNumber);
                this.processPage(container, pageNumber, p!, p?.content, resolve, reject);
                if (data.comparePages.length > 0) {
                  if (this.isLeftContainer(container)) {
                    this.leftPageLoaded = true;
                  } else {
                    this.rightPageLoaded = true;
                  }
                  this.showLoader(false);
                }
                this.showLoader(false);
              } else {
                if (!skipPageConversionLoader) {
                  this.autoNavigatePageInSignalR = isAutoNavigationEnable;
                  if (this.isLeftContainer(container)) {
                    this.pageConversionEnglishPageNumber = pageNumber;
                    this.leftPageLoaded = false;
                  } else {
                    this.pageConversionChinesePageNumber = pageNumber;
                    this.rightPageLoaded = false;
                  }
                }
                container.pageLoadFailed = true;
                if (isGoToPageRequest) {
                  reject(AppConstants.PageConversionWipError);
                } else {
                  resolve(false);
                }
              }
            },
            error: (err: any) => {
              container.pageLoadFailed = true;
              if (err === AppConstants.ErrorSessionTimeout || err?.status === 401) {
                this.notificationService.error(AppConstants.ErrorSessionTimeoutTitle, AppConstants.ErrorSessionTimeout);
              }
              resolve(false);
            },
          });
        }
      } else {
        this.processPage(container, pageNumber, page, pageContent, resolve, reject);
      }
    }
  }

  private isPageStatusLocked(pageStatus: PageStatus): boolean {
    return pageStatus === PageStatus.Completed;
  }

  private processPage(
    container: ContainerDetail,
    pageNumber: number,
    page: PageDto,
    pageContent: string,
    resolve: (value: boolean) => void,
    reject: (reason?: any) => void
  ) {
    const blob = base64StringToBlob(pageContent, 'text/html');
    blob
      .text()
      .then((text) => {
        if (text) {
          page.content = '';
          $(container.frameElement).contents().find('html').html(text);
          container.url = page.fileName;
          container.currentPageNumber = pageNumber;
          container.currentPageStatus = page.pageStatus;
          container.currentPageIdentity = page.pageIdentity;
          container.isBookmarked = page.isBookmarked;
          container.pdfFilePath = page.pdfFilePath;
          container.isDirty = false;
          container.currentPageRegions = page.currentPageIgnoreRegion ? JSON.parse(page.currentPageIgnoreRegion) : [];
          this.onIframeLoad(container);
          const pageRect = this.getHeightWidth(container);
          container.iFrameHeight = pageRect.height;
          container.iFrameWidth = pageRect.width;
          this.setWidthForMonoligual(container);
          resolve(true);
        }
      })
      .then(() => {
        if (pageNumber % 4 === 0) {
          const pageIds: number[] = container.pages
            .filter((x) => x.pageSequence > pageNumber && !x.content)
            .map((p) => p.pageIdentity)
            .slice(0, 5);
          if (pageIds.length > 0) {
            const getPageDetailRequest: GetPageDetailRequest = {
              jobTypeId: this.fileTypeId,
              fileId: container.fileId,
              pageIds,
              currentPageNumber: pageNumber,
              jobIdentity: this.jobIdentity,
              viewerSide: container.id!,
            };
            this.fileCompareService.GetPageDetails(getPageDetailRequest).subscribe({
              next: (data: GetPageDetailResponse) => {
                this.isJobLocked = data.isLocked;
                if (data.isLocked) {
                  this.notificationService.error(AppConstants.ErrorJobLockedTitle, AppConstants.ErrorJobLockedMessage);
                }
                const pages: PageDto[] = data.comparePages;
                if (pages.length > 0) {
                  pages.forEach((p) => {
                    const index = container.pages.findIndex((x) => x.pageIdentity === p.pageIdentity);
                    if (index > -1) {
                      container.pages[index] = p;
                    }
                  });
                }
              },
              error: (error) => {
                container.pageLoadFailed = true;
                if (error?.status === 401) {
                  this.notificationService.error(AppConstants.ErrorSessionTimeoutTitle, AppConstants.ErrorSessionTimeout);
                }
                resolve(false);
              },
            });
          }
        }
      });
  }

  private getHeightWidth(container: ContainerDetail): { height: number; width: number } {
    let fileSizes: { height: number; width: number } = { height: 1180, width: 900 };
    const documentContainer = $(container.frameElement).contents().find('html');
    $.each($(documentContainer).find('span:is(.height-width)'), (i: any, e: any) => {
      fileSizes.height = ($(e).attr('height') * 96) / 72;
      fileSizes.width = ($(e).attr('width') * 96) / 72;
    });
    return fileSizes;
  }

  private setWidthForMonoligual(container: ContainerDetail) {
    if (!(this.isMonolingualSearch || this.isSquareBracketSingleFile)) {
      return;
    }
    const ele = document.getElementsByClassName('iFrameContainer-monolingual')[0] as HTMLElement;
    if (ele) {
      ele.style.width = `${container.iFrameWidth + 20}px`;
    }
  }

  private sortFactList(list: Fact[]): void {
    list.sort((a, b) => {
      if (a.top - b.top > 5) {
        return 1;
      }
      if (a.top === b.top) {
        return 0;
      }
      return -1;
    });

    list.sort((a, b) => {
      if (a.top - b.top >= 5) {
        return 0;
      }
      return a.left - b.left;
    });
  }

  public navigateNext(leftContainer: ContainerDetail, rightContainer: ContainerDetail, manualSave: boolean = false): void {
    // for synchronousScrollingMode the pages on both sides should to be navigated symultaneously.
    let navigatePages = true;
    if (
      (this.syncScrollingModeControl.value || this.isMonolingualSearch || this.isSquareBracketSingleFile) &&
      (leftContainer.factList.length > 0 || rightContainer.factList.length > 0)
    ) {
      navigatePages = false;
    }

    // save the pages if no more fact elements are pending to be processed
    let p1: Promise<boolean>;
    let p2: Promise<boolean>;
    let p3: Promise<boolean>;
    if (
      manualSave ||
      (leftContainer.factList.length === 0 && navigatePages && (leftContainer.isDirty || leftContainer.factListHistory.length !== 0))
    ) {
      // Save page
      leftContainer.factList.length = 0;
      leftContainer.factListHistory.length = 0;
      p3 = new Promise((resolve3, reject) => {
        this.savePage(leftContainer, resolve3, reject);
      });
      // load next page
      if (!this.isLastPage(leftContainer)) {
        leftContainer.pageLoaded = false;
        leftContainer.pageLoadFailed = false;
        p2 = this.waitForIframeLoad(leftContainer);
        p1 = p3.then(() => {
          return new Promise((resolve1, reject) => {
            this.loadPage(leftContainer, leftContainer.currentPageNumber + 1, resolve1, reject, false, false, true);
          });
        });
      } else {
        p1 = Promise.resolve(true);
        p2 = Promise.resolve(true);
      }
    } else {
      p1 = Promise.resolve(true);
      p2 = Promise.resolve(true);
      p3 = Promise.resolve(true);
    }

    let p4: Promise<boolean>;
    let p5: Promise<boolean>;
    let p6: Promise<boolean>;
    if (
      (manualSave && !this.isMonolingualSearch && !this.isSquareBracketSingleFile) ||
      (rightContainer.factList.length === 0 && navigatePages && (rightContainer.isDirty || rightContainer.factListHistory.length !== 0))
    ) {
      // Save page
      rightContainer.factList.length = 0;
      rightContainer.factListHistory.length = 0;
      p5 = new Promise((resolve5, reject) => {
        this.savePage(rightContainer, resolve5, reject);
      });
      // load next page
      if (!this.isLastPage(rightContainer)) {
        rightContainer.pageLoaded = false;
        rightContainer.pageLoadFailed = false;
        p6 = this.waitForIframeLoad(rightContainer);
        p4 = p5.then(() => {
          return new Promise((resolve4, reject) => {
            this.loadPage(rightContainer, rightContainer.currentPageNumber + 1, resolve4, reject, false, false, true);
          });
        });
      } else {
        p4 = Promise.resolve(true);
        p6 = Promise.resolve(true);
      }
    } else {
      p4 = Promise.resolve(true);
      p5 = Promise.resolve(true);
      p6 = Promise.resolve(true);
    }

    Promise.all([p1, p2, p3, p4, p5, p6]).then(([result1, result2, result3, result4, result5, result6]): any => {
      if (result1 && result2 && result4 && result6) {
        if (leftContainer.factList.length > 0 || rightContainer.factList.length > 0) {
          if (this.fileTypeId === FeatureType.Call_Redaction || !this.validateValue(leftContainer, rightContainer)) {
            if (leftContainer.factList.length > 0 && rightContainer.factList.length > 0) {
              this.scrollAndSelectFirstElement(leftContainer);
              this.scrollAndSelectFirstElement(rightContainer);
              return false;
            }
            leftContainer.factList.length > 0
              ? this.scrollAndSelectFirstElement(leftContainer)
              : this.scrollAndSelectFirstElement(rightContainer);
            return false;
          }
          this.markAccept(leftContainer, rightContainer, FrameType.Both, false);
        } else {
          let leftCompleted = false;
          let rightCompleted = false;
          if (this.isLastPage(leftContainer) && (leftContainer.factList.length === 0 || manualSave)) {
            leftContainer.currentPageStatus = PageStatus.Completed;
            leftCompleted = true;
          }
          if (
            this.isLastPage(rightContainer) &&
            !this.isMonolingualSearch &&
            !this.isSquareBracketSingleFile &&
            (rightContainer.factList.length === 0 || manualSave)
          ) {
            rightContainer.currentPageStatus = PageStatus.Completed;
            rightCompleted = true;
          }
          if (!this.isMonolingualSearch && !this.isSquareBracketSingleFile) {
            if (
              !leftContainer.isDirty &&
              rightContainer.factListHistory.length === 0 &&
              !rightContainer.isDirty &&
              rightContainer.factListHistory.length === 0
            ) {
              return;
            }
            if (leftCompleted && rightCompleted) {
              return;
            } else {
              if (
                leftCompleted ||
                rightCompleted ||
                (this.syncScrollingModeControl.value && (leftContainer.factList.length > 0 || rightContainer.factList.length > 0))
              ) {
                this.scrollAndSelectFirstElement(leftContainer);
                this.scrollAndSelectFirstElement(rightContainer);
                return false;
              }
            }
          } else {
            // if leftcontainer has no factlist and saved this function should get return
            if (leftCompleted || !leftContainer.isDirty) {
              return;
            } else {
              if (leftContainer.factList.length > 0) {
                this.scrollAndSelectFirstElement(leftContainer);
                return false;
              }
            }
          }
        }
        this.navigateNext(leftContainer, rightContainer, false);
      } else {
        if (result1 && leftContainer.factList.length > 0) {
          this.scrollAndSelectFirstElement(leftContainer);
        }
        if (result4 && rightContainer.factList.length > 0) {
          this.scrollAndSelectFirstElement(rightContainer);
        }
      }
    });
  }

  private isLastPage(container: ContainerDetail) {
    return container.currentPageNumber === container.pageCount;
  }

  private savePage(
    container: ContainerDetail,
    resolve: (value: boolean | PromiseLike<boolean>) => void,
    reject: (reason?: any) => void,
    progressShown: boolean = true
  ): void {
    if (progressShown) {
      this.commonService.showSpinner.next(true);
    }
    const pageIdentity = container.currentPageIdentity;
    const htmlFile: File | null = this.getHtmlFile(container.frameElement, `${pageIdentity}.html`);
    if (htmlFile || this.fileTypeId === this.featureType.Financial_Table_Check) {
      const formData: FormData = new FormData();
      formData.append('pageIdentity', pageIdentity.toString());
      if (this.fileTypeId === this.featureType.Financial_Table_Check) {
        formData.append('fileTypeId', this.fileTypeId.toString());
      } else {
        formData.append('file', htmlFile!);
      }

      this.fileCompareService.SavePage(formData).subscribe({
        next: () => {
          if (progressShown) {
            this.commonService.showSpinner.next(false);
          }
          container.isDirty = false;
          resolve(true);
        },
        error: (err) => {
          this.notificationService.error(`${AppConstants.SavePageConfirmationTitle} - Page: ${container.currentPageNumber}`, err);
          reject(err);
        },
      });
    }
  }

  private getHtmlFile(frameElement: HTMLIFrameElement, fileName: string): File | null {
    const htmlElement = $(frameElement).contents().find('html')[0];
    if (htmlElement) {
      const htmlSource: string = $(frameElement).contents().find('html')[0].outerHTML;
      const htmlBlob: any = new Blob([htmlSource], { type: 'text/html' });
      htmlBlob.lastModifiedDate = new Date();
      htmlBlob.name = fileName;
      return htmlBlob as File;
    } else {
      return null;
    }
  }

  private validateValue(leftContainer: ContainerDetail, rightContainer: ContainerDetail): boolean {
    const isLeftFactGlossary = this.isGlossary(leftContainer);
    const isRightFactGlossary = this.isGlossary(rightContainer);
    if (!isLeftFactGlossary && !isRightFactGlossary) {
      return this.getCurrentFactValue(leftContainer) === this.getCurrentFactValue(rightContainer);
    }
    const leftContainerValue = this.getCurrentFactValue(leftContainer).split('|');
    const rightContainerValue = this.getCurrentFactValue(rightContainer).split('|');
    for (let index = 0; index < leftContainerValue.length; index++) {
      const element = leftContainerValue[index];
      if (rightContainerValue.includes(element)) {
        return true;
      }
    }
    return false;
  }

  private isGlossary(container: ContainerDetail, index: number = 0): boolean {
    if (!container.factList[index]) {
      return false;
    }
    const containerFact = container.factList[index];
    if (containerFact.isglossary) {
      return true;
    } else {
      return false;
    }
  }

  private getCurrentFactValue(container: ContainerDetail, index: number = 0): string {
    if (!container.factList[index]) {
      return '';
    }
    const containerFact = container.factList[index];
    let containerFactValue = '';
    if (containerFact != null) {
      if (!containerFact.casesensitive) {
        containerFactValue = containerFact.value.toLowerCase();
      } else {
        containerFactValue = containerFact.value;
      }
    }
    return containerFactValue;
  }

  public getCurrentIndexValue(container: ContainerDetail, index: number = 0): string {
    if (!container.factList[index]) {
      return '';
    }
    return container.factList[index].value;
  }

  public getPopoverContent(container: ContainerDetail, index: number = 0): string {
    if (!container.factList[index]) {
      return '';
    }
    return container.factList[index].value?.replace(/\|/gi, '<br/>');
  }

  private scrollToElement(container: ContainerDetail, currentIndex: number): void {
    $(container.frameElement)
      .contents()
      .scrollTop($(container.factList[currentIndex].element).offset().top - 50);
  }

  public markAccept(
    leftContainer: ContainerDetail,
    rightContainer: ContainerDetail,
    frameType: FrameType,
    navigateNext: boolean,
    leftIndex: number = 0,
    rightIndex: number = 0
  ): void {
    switch (frameType) {
      case FrameType.Left:
        this.markElementAsAccepted(leftContainer, leftIndex);
        break;

      case FrameType.Right:
        this.markElementAsAccepted(rightContainer, rightIndex);
        break;

      default: // FrameType.Both
        this.markElementAsAccepted(leftContainer, leftIndex);
        this.markElementAsAccepted(rightContainer, rightIndex);
        break;
    }

    if (navigateNext) {
      this.navigateNext(leftContainer, rightContainer, false);
    }
  }

  public markReject(
    leftContainer: ContainerDetail,
    rightContainer: ContainerDetail,
    frameType: FrameType,
    navigateNext: boolean,
    leftIndex: number = 0,
    rightIndex: number = 0
  ): void {
    switch (frameType) {
      case FrameType.Left:
        this.markElementAsRejected(leftContainer, leftIndex);
        break;

      case FrameType.Right:
        this.markElementAsRejected(rightContainer, rightIndex);
        break;

      default: // FrameType.Both
        this.markElementAsRejected(leftContainer, leftIndex);
        this.markElementAsRejected(rightContainer, rightIndex);
        break;
    }

    if (navigateNext) {
      this.navigateNext(leftContainer, rightContainer, false);
    }
  }

  private markAcceptReject(
    leftContainer: ContainerDetail,
    rightContainer: ContainerDetail,
    frameType: FrameType,
    navigateNext: boolean,
    leftIndex: number = 0,
    rightIndex: number = 0
  ): void {
    switch (frameType) {
      case FrameType.Left:
        this.markElementAsAccepted(leftContainer, leftIndex);
        this.markElementAsRejected(rightContainer, leftIndex);
        break;

      case FrameType.Right:
        this.markElementAsRejected(leftContainer, leftIndex);
        this.markElementAsAccepted(rightContainer, rightIndex);
        break;
    }

    if (navigateNext) {
      this.navigateNext(leftContainer, rightContainer, false);
    }
  }

  public undoLastAction(
    container1: ContainerDetail,
    container2: ContainerDetail | null = null,
    container1Index: number = 0,
    container2Index: number = 0
  ): void {
    this.scrollAndSelectFirstElement(container1);
    this.undoLastActionFunc(container1, container1Index);

    if (container2 !== null) {
      this.scrollAndSelectFirstElement(container2);
      this.undoLastActionFunc(container2, container2Index);
    }
  }

  private undoLastActionFunc(container: ContainerDetail, currentIndex: number): void {
    if (container.factList[currentIndex] !== undefined) {
      $(container.factList[currentIndex].element).removeClass(AppConstants.SelectedFactClassName);
      $(container.factListHistory[currentIndex].element)
        .removeClass(
          `${AppConstants.AcceptedFactClassName} ${AppConstants.RejectedFactClassName} ${AppConstants.GlossaryAcceptedFactClassName} ${AppConstants.GlossaryRejectedFactClassName}`
        )
        .addClass(AppConstants.SelectedFactClassName);
      container.factList.unshift(container.factListHistory.splice(currentIndex, 1)[0]);
    }
  }

  private markElementAsAccepted(container: ContainerDetail, currentIndex: number): void {
    // Add accepted class and remove the selected class
    const fact = container.factList[currentIndex];
    if (fact !== undefined) {
      const factElement = $(fact.element);
      let acceptedClassName = AppConstants.AcceptedFactClassName;
      if (factElement.hasClass(AppConstants.GlossaryFactClassName) || factElement.hasClass(AppConstants.SquareBracketGlossaryClassName)) {
        acceptedClassName = AppConstants.GlossaryAcceptedFactClassName;
      } else if (factElement.hasClass(AppConstants.SquareBracketClassName) || factElement.hasClass(AppConstants.SquareBracket1ClassName)) {
        acceptedClassName = AppConstants.GlossaryFactClassName;
      }
      factElement.addClass(acceptedClassName).removeClass(AppConstants.SelectedFactClassName);
      container.factListHistory.unshift(container.factList.splice(currentIndex, 1)[0]);
      container.isDirty = true;
    }
  }

  private markElementAsRejected(container: ContainerDetail, currentIndex: number): void {
    const factList = container.factList;
    if (factList[currentIndex] !== undefined) {
      const factElement = $(factList[currentIndex].element);
      const rejectedClassName = $(factElement).hasClass(AppConstants.GlossaryFactClassName)
        ? AppConstants.GlossaryRejectedFactClassName
        : AppConstants.RejectedFactClassName;
      $(factElement).addClass(rejectedClassName).removeClass(AppConstants.SelectedFactClassName);
      const [removedFact] = factList.splice(currentIndex, 1);
      container.factListHistory.unshift(removedFact);
      container.isDirty = true;
    }
  }

  private markElementAsIgnored(element: HTMLElement): void {
    // Add ignored class and remove the selected class
    $(element).addClass(AppConstants.IgnoredFactClassName).removeClass(AppConstants.SelectedFactClassName);
  }

  private markElementAsNotSelected(container: ContainerDetail, currentIndex: number): void {
    // remove selected class
    $(container.factList[currentIndex].element).removeClass(AppConstants.SelectedFactClassName);
  }

  public onAcceptClick(frameType: FrameType): void {
    this.markAccept(this.leftContainer, this.rightContainer, frameType, true);
  }

  public onRejectClick(frameType: FrameType): void {
    this.markReject(this.leftContainer, this.rightContainer, frameType, true);
  }

  public onAcceptAndRejectClick(frameType: FrameType): void {
    this.markAcceptReject(this.leftContainer, this.rightContainer, frameType, true);
  }

  public onSwapClick(container: ContainerDetail): void {
    if (container.factList.length > 1) {
      this.markElementAsNotSelected(container, 0);
      this.swapArrayElements(container.factList, 0, 1);
      this.navigateNext(this.leftContainer, this.rightContainer, false);
    }
  }

  private swapArrayElements(arr: any[], indexA: number, indexB: number): void {
    const temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
  }

  public onPreviousClick(container: ContainerDetail): void {
    // load previous page
    this.onNavigate(container, container.currentPageNumber - 1, true);
  }

  public onNextClick(container: ContainerDetail): void {
    // load next page
    this.onNavigate(container, container.currentPageNumber + 1, true);
  }

  public onNavigate(container: ContainerDetail, pageNumber: number, skipPageConversionLoader: boolean): void {
    // load specific page
    this.leftPageLoaded = false;
    this.rightPageLoaded = false;
    if (!this.syncScrollingModeControl.value) {
      if (this.isLeftContainer(container)) {
        this.pageConversionEnglishPageNumber = pageNumber;
      } else {
        this.pageConversionChinesePageNumber = pageNumber;
      }
      this.navigateToPage(
        container,
        null,
        pageNumber,
        0,
        this.hasDifferentPages(container.currentPageNumber) || container.factList.length === 0,
        skipPageConversionLoader
      );
    } else {
      this.pageConversionEnglishPageNumber = pageNumber;
      this.pageConversionChinesePageNumber = pageNumber;
      this.navigateToPage(
        this.leftContainer,
        this.rightContainer,
        pageNumber,
        pageNumber,
        this.hasDifferentPages(this.leftContainer.currentPageNumber) || this.hasDifferentPages(this.rightContainer.currentPageNumber),
        skipPageConversionLoader
      );
    }
  }

  private navigateToPage(
    container1: ContainerDetail | null,
    container2: ContainerDetail | null,
    container1PageNumber: number = 0,
    container2PageNumber: number = 0,
    skipIsDirtyCheck: boolean = false,
    skipPageConversionLoader: boolean = true,
    autoStartComparison: boolean = false,
    isGoToPageRequest: boolean = false
  ): void {
    if (
      (!skipIsDirtyCheck || this.isFinancialCheckSaved()) &&
      this.isDirty(container1, container2) &&
      !this.skipDirtyOnSyncMode(container1!, container2)
    ) {
      this.setUnsavedDocumentStyle(container1!, container2);
      const dialogOptions: DialogOptions = this.getUnSavedPageOptions(container1!, container2);
      const dialogRef = this.dialogService.open(ConfirmDialog, dialogOptions);
      this.dialogService.confirmed(dialogRef).subscribe((confirm) => {
        this.removeUnsavedDocumentStyle();
        if (confirm) {
          this.navigateToPageFunc(
            container1,
            container2,
            container1PageNumber,
            container2PageNumber,
            autoStartComparison,
            isGoToPageRequest,
            skipPageConversionLoader
          );
        }
      });
    } else {
      this.navigateToPageFunc(
        container1,
        container2,
        container1PageNumber,
        container2PageNumber,
        autoStartComparison,
        isGoToPageRequest,
        skipPageConversionLoader
      );
    }
  }

  private getUnSavedPageOptions(container1: ContainerDetail, container2: ContainerDetail | null): DialogOptions {
    const dialogOptions: DialogOptions = AppConstants.UnSavedPageOptions;
    if (container1.isDirty && container2 !== null && container2.factList.length !== 0 && container2.isDirty) {
      dialogOptions.message = AppConstants.UnSavedPageDialogMessageBoth;
    } else if (container1.isDirty) {
      dialogOptions.message = this.getDialogMessage(container1);
    } else if (container2 !== null && container2.isDirty) {
      dialogOptions.message = this.getDialogMessage(container2);
    }
    return dialogOptions;
  }

  private getDialogMessage(container: ContainerDetail): string {
    return this.isLeftContainer(container) ? AppConstants.UnSavedPageDialogMessageEnglish : AppConstants.UnSavedPageDialogMessageChinese;
  }

  private setUnsavedDocumentStyle(container1: ContainerDetail, container2: ContainerDetail | null): void {
    if (container1.isDirty) {
      container1.unSavedDocumentStyle = 'documentUnsaved';
    }
    if (container2 !== null && container2.factListHistory.length !== 0 && container2.isDirty) {
      container2.unSavedDocumentStyle = 'documentUnsaved';
    }
  }

  private removeUnsavedDocumentStyle(): void {
    this.leftContainer.unSavedDocumentStyle = '';
    this.rightContainer.unSavedDocumentStyle = '';
  }

  private isDirty(container1: ContainerDetail | null, container2: ContainerDetail | null): boolean {
    if (this.isFinancialCheckType) {
      return container1 !== null && container1.isDirty ? this.leftPdfViewer.leftPdfViewer.annotationCollection.length > 0 : false;
    } else {
      return (
        (container1 !== null && container1.isDirty && container1.factListHistory.length !== 0) ||
        (container2 !== null && container2.factList.length !== 0 && container2.isDirty && container2.factListHistory.length !== 0)
      );
    }
  }

  private navigateToPageFunc(
    container1: ContainerDetail | null,
    container2: ContainerDetail | null,
    container1PageNumber: number,
    container2PageNumber: number,
    autoStartComparison: boolean,
    isGoToPageRequest: boolean = false,
    skipPageConversionLoader: boolean = true
  ) {
    let p1: Promise<boolean>;
    let p2: Promise<boolean>;
    let p3: Promise<boolean>;
    let p4: Promise<boolean>;

    if (container1 !== null && this.isValidPageNumber(container1, container1PageNumber)) {
      container1.pageLoaded = false;
      container1.pageLoadFailed = false;
      p2 = this.waitForIframeLoad(container1);
      p1 = new Promise((resolve1, reject) => {
        this.loadPage(container1, container1PageNumber, resolve1, reject, isGoToPageRequest, skipPageConversionLoader);
      });
    } else {
      p1 = Promise.resolve(true);
      p2 = Promise.resolve(true);
    }

    if (container2 !== null && this.isValidPageNumber(container2, container2PageNumber)) {
      container2.pageLoaded = false;
      container2.pageLoadFailed = false;
      p4 = this.waitForIframeLoad(container2);
      p3 = new Promise((resolve1, reject) => {
        this.loadPage(container2, container2PageNumber, resolve1, reject, isGoToPageRequest, skipPageConversionLoader);
      });
    } else {
      p3 = Promise.resolve(true);
      p4 = Promise.resolve(true);
    }

    Promise.all([p1, p2, p3, p4])
      .then(([result1, result2, result3, result4]) => {
        if (result1 && result3) {
          if (!autoStartComparison) {
            // scroll and select the element
            if (container1 !== null) {
              this.scrollAndSelectFirstElement(container1);
            }
            if (container2 !== null) {
              this.scrollAndSelectFirstElement(container2);
            }
          } else {
            if (this.areBothContainerUnLocked()) {
              if (
                (this.jobInformation.typeId === FeatureType.Call_Redaction && this.differentRedactedPages.length === 0) ||
                (this.jobInformation.typeId === FeatureType.Square_Bracket_Check &&
                  (this.differentSquareBracketPages.length === 0 || this.differentSquareBracketPages === null))
              ) {
                return this.selectFirstElement();
              }
              this.navigateNext(this.leftContainer, this.rightContainer, false);
            }
          }
        }
      })
      .catch((error) => {
        if (isGoToPageRequest && error === AppConstants.PageConversionWipError) {
          // show the AppConstants.PageConversionWipError dialog
          const dialogRef = this.dialogService.open(AlertDialog, AppConstants.PageConversionConfirmDialogOptions);
          this.dialogService.confirmed(dialogRef).subscribe((result) => {
            if (result) {
              // show the goto page dialog again
              this.goToPage(container1PageNumber, container2PageNumber, true);
            }
          });
        }
      });
  }

  private isValidPageNumber(container: ContainerDetail, pageNumber: number): boolean {
    if (pageNumber < 1 || pageNumber > container.pageCount) {
      return false;
    }
    return true;
  }

  private scrollAndSelectFirstElement(container: ContainerDetail): void {
    if (container.currentPageStatus !== 1 && container.factList.length > 0) {
      $(container.factList[0].element).addClass(AppConstants.SelectedFactClassName);
      this.scrollToElement(container, 0);
    }
  }

  private unSelectSwappedElement(container: ContainerDetail): void {
    if (container.currentPageStatus !== 1 && container.factList.length > 1) {
      // remove the selection for 2nd element.
      $(container.factList[1].element).removeClass(AppConstants.SelectedFactClassName);
    }
  }

  public saveComparePage(): void {
    const dialogRef = this.dialogService.open(ConfirmDialog, AppConstants.SavePageOptions);
    this.dialogService.confirmed(dialogRef).subscribe((confirm) => {
      if (confirm) {
        if (this.isFinancialCheckType) {
          this.saveFinancialTableCheckPage();
        } else {
          this.navigateNext(this.leftContainer, this.rightContainer, true);
        }
      }
    });
  }

  public bookmarkPages(): void {
    this.showLoader(true);
    const isBookmarked: boolean = !(this.leftContainer.isBookmarked && this.rightContainer.isBookmarked);

    const bookmarkPages: BookmarkPageInfo[] = [];
    if (this.leftContainer.isBookmarked !== isBookmarked) {
      bookmarkPages.push({ pageIdentity: this.leftContainer.currentPageIdentity, isBookmarked });
    }
    if (
      this.rightContainer.isBookmarked !== isBookmarked &&
      !this.isMonolingualSearch &&
      !this.isSquareBracketSingleFile &&
      !this.isFinancialCheckType
    ) {
      bookmarkPages.push({ pageIdentity: this.rightContainer.currentPageIdentity, isBookmarked });
    }

    this.fileCompareService.BookmarkPage(this.jobIdentity, bookmarkPages).subscribe({
      next: () => {
        this.leftContainer.isBookmarked = isBookmarked;
        this.rightContainer.isBookmarked = isBookmarked;
        this.leftContainer.pages.filter((x) => x.pageSequence === this.leftContainer.currentPageNumber)[0].isBookmarked = isBookmarked;
        this.showLoader(false);
      },
      error: (error) => {
        this.notificationService.error('Bookmark Page', error);
      },
    });
  }

  public bookmarkPage(container: ContainerDetail): void {
    const isBookmarked: boolean = !container.isBookmarked;
    const bookmarkPages: BookmarkPageInfo[] = [{ pageIdentity: container.currentPageIdentity, isBookmarked }];

    this.fileCompareService.BookmarkPage(this.jobIdentity, bookmarkPages).subscribe({
      next: () => {
        container.isBookmarked = isBookmarked;
      },
      error: (error) => {
        this.notificationService.error('Bookmark Page', error);
      },
    });
  }

  public viewSourcePdf(): void {
    this.commonService.viewSourcePdf(
      this.jobIdentity,
      this.id.id,
      this.jobInformation.typeId,
      this.leftContainer,
      this.rightContainer,
      this.isSquareBracketSingleFile,
      this.jobInformation
    );
  }

  public viewJobInfo(): void {
    this.commonService.viewJobInfo(this.jobInformation);
  }

  public goToPage(leftContainerPageNumber: number, rightContainerPageNumber: number, skipIsDirtyCheck: boolean = false): void {
    const goToPageOptions = {
      title: AppConstants.GoToPage,
      confirmText: AppConstants.OkButtonLabel,
      cancelText: AppConstants.CancelButtonLabel,
      englishPageNumber: leftContainerPageNumber,
      chinesePageNumber: rightContainerPageNumber,
      englishPageCount: this.leftContainer.pageCount,
      chinesePageCount: this.rightContainer.pageCount,
    };
    const dialogRef = this.dialogService.open(GoToPageComponent, goToPageOptions);
    this.dialogService.confirmed(dialogRef).subscribe((result) => {
      if (result !== false) {
        this.pageConversionEnglishPageNumber = result.englishPageNumber;
        this.pageConversionChinesePageNumber = result.chinesePageNumber;
        this.leftPageLoaded = false;
        this.rightPageLoaded = false;
        this.navigateToPage(
          this.leftContainer,
          this.rightContainer,
          result.englishPageNumber,
          result.chinesePageNumber,
          skipIsDirtyCheck,
          false,
          !this.isSquareBracketCheckType
        );
      }
    });
  }

  public resetPageStatus(container1: ContainerDetail, container2: ContainerDetail | null = null): void {
    const dialogRef = this.dialogService.open(ConfirmDialog, AppConstants.UnlockPageConfirmDialogOptions);
    this.dialogService.confirmed(dialogRef).subscribe((result) => {
      if (result) {
        if (this.isFinancialCheckType) {
          this.resetFinancialCheckPage();
          return;
        }
        const isCompleted1: boolean = this.isPageCompleted(container1);
        const resetPages: ResetPageInfo[] = [{ pageIdentity: container1.currentPageIdentity, isCompleted: isCompleted1 }];

        let isCompleted2: boolean;
        if (container2 !== null && !this.isSquareBracketSingleFile) {
          isCompleted2 = this.isPageCompleted(container2);
          resetPages.push({ pageIdentity: container2.currentPageIdentity, isCompleted: isCompleted2 });
        }

        this.fileCompareService.ResetPageDetails(this.jobIdentity, resetPages).subscribe({
          next: () => {
            // reload the current page
            let p1: Promise<boolean>;
            let p2: Promise<boolean>;
            let p3: Promise<boolean>;
            let p4: Promise<boolean>;

            container1.pageLoaded = false;
            container1.pageLoadFailed = false;
            p3 = this.waitForIframeLoad(container1);
            p1 = new Promise((resolve, reject) => {
              this.loadPage(container1, container1.currentPageNumber, resolve, reject);
            });

            if (container2 !== null) {
              container2.pageLoaded = false;
              container2.pageLoadFailed = false;
              p4 = this.waitForIframeLoad(container2);
              p2 = new Promise((resolve, reject) => {
                this.loadPage(container2, container2.currentPageNumber, resolve, reject);
              });
            } else {
              p4 = Promise.resolve(true);
              p2 = Promise.resolve(true);
            }

            Promise.all([p1, p2, p3, p4]).then(([result1, result2, result3, result4]) => {
              container1.currentPageStatus = PageStatus.New;
              this.unSelectSwappedElement(container1);
              this.scrollAndSelectFirstElement(container1);
              if (!isCompleted1) {
                container1.isDirty = true; // set isDirty to true if it is incompleted page
              }

              if (container2 !== null) {
                container2.currentPageStatus = PageStatus.New;
                this.unSelectSwappedElement(container2);
                this.scrollAndSelectFirstElement(container2);
                if (!isCompleted2) {
                  container2.isDirty = true; // set isDirty to true if it is incompleted page
                }
              }
            });
          },
          error: (error) => {
            this.notificationService.error(AppConstants.UnlockPageConfirmationTitle, error);
          },
        });
      }
    });
  }
  private isPageCompleted(container: ContainerDetail): boolean {
    return (
      $(container.frameElement).contents().find('html fact:not(.accepted,.rejected,.glossary-accepted,.glossary-rejected,.ignored)')
        .length === 0
    );
  }

  public loadDashboard(): void {
    if (
      this.isDirty(this.leftContainer, this.rightContainer) ||
      this.skipDirtyCheck() ||
      (!this.skipDirtyOnSyncMode(this.leftContainer, this.rightContainer) && this.isFinancialCheckSaved())
    ) {
      this.setUnsavedDocumentStyle(this.leftContainer, this.rightContainer);
      const dialogOptions: DialogOptions = this.getUnSavedPageOptions(this.leftContainer, this.rightContainer);
      const dialogRef = this.dialogService.open(ConfirmDialog, dialogOptions);
      this.dialogService.confirmed(dialogRef).subscribe((confirm) => {
        this.removeUnsavedDocumentStyle();
        if (confirm) {
          this.router.navigate(['/jobs']);
        }
      });
    } else {
      this.router.navigate(['/jobs']);
    }
  }

  public updateCurrentPageRegions(): void {
    if (this.regionToggleControl.value) {
      // File level
      if (this.leftContainer.tmpCurrentPageRegions.length > 0 || this.rightContainer.tmpCurrentPageRegions.length > 0) {
        // todo: show warning that the current page regions will be cleared
        const dialogRef = this.dialogService.open(ConfirmDialog, AppConstants.CloseCurrentPageConfirmDialogOptions);
        this.dialogService.confirmed(dialogRef).subscribe((confirmed) => {
          if (!confirmed) {
            this.regionToggleControl.setValue(false);
            return;
          } else {
            this.loadFileRegions();
            this.resetCurrentPageRegions();
            this.canvasRedrawFn();
          }
        });
      } else {
        this.loadFileRegions();
        this.resetCurrentPageRegions();
        this.canvasRedrawFn();
      }
    } else {
      // page level
      this.loadPageRegions(false);
      this.canvasRedrawFn();
    }
  }

  private canvasRedrawFn() {
    setTimeout(() => {
      this.leftCanvas.redrawFn();
      if (this.rightCanvas) {
        this.rightCanvas.redrawFn();
      }
    });
  }

  public setRegionButtonClicked(): void {
    this.isIgnoreRegionSelected = true;
    const hasCurrentPageRegions: boolean = this.hasCurrentPageRegionsfn();
    this.showRegionCanvas = true;

    if (!hasCurrentPageRegions) {
      // File level
      this.regionToggleControl.setValue(true);
      this.loadFileRegions();
      this.resetCurrentPageRegions();
    } else {
      // page level
      this.regionToggleControl.setValue(false);
      this.loadPageRegions();
    }
    setTimeout(() => {
      this.setElementHeightWidth(this.leftContainer, 'left-doc');
      this.setElementHeightWidth(this.rightContainer, 'right-doc');
      const ignoreRegionEle = document.getElementById('compare-page');
      if (ignoreRegionEle && !(this.isMonolingualSearch || this.isSquareBracketSingleFile)) {
        ignoreRegionEle.style.width = `${
          this.getHeightWidth(this.leftContainer).width + this.getHeightWidth(this.rightContainer).width + 10
        }px`;
      }
    });
  }

  private setElementHeightWidth(container: ContainerDetail, id: string): void {
    if (container) {
      const frameEle = document.getElementById(id);
      if (frameEle) {
        frameEle.style.height = `${this.getHeightWidth(container).height + 5}px`;
        frameEle.style.width = `${this.getHeightWidth(container).width + 5}px`;
      }
    }
  }

  private hasCurrentPageRegionsfn(): boolean {
    let currentPage: PageDto = this.getCurrentPageDto(this.leftContainer);
    if (
      currentPage &&
      currentPage.currentPageIgnoreRegion != null &&
      currentPage.currentPageIgnoreRegion !== '[]' &&
      currentPage.currentPageIgnoreRegion!.length > 0
    ) {
      return true;
    }
    currentPage = this.getCurrentPageDto(this.rightContainer);
    if (
      currentPage &&
      currentPage.currentPageIgnoreRegion != null &&
      currentPage.currentPageIgnoreRegion !== '[]' &&
      currentPage.currentPageIgnoreRegion!.length > 0
    ) {
      return true;
    }
    return false;
  }

  private resetCurrentPageRegions(): void {
    this.leftContainer.tmpCurrentPageRegions = [];
    this.rightContainer.tmpCurrentPageRegions = [];
  }

  private loadFileRegions(): void {
    this.leftContainer.tmpFileRegions = JSON.parse(JSON.stringify(this.leftContainer.fileRegions)); // clone object without reference
    this.rightContainer.tmpFileRegions = JSON.parse(JSON.stringify(this.rightContainer.fileRegions));
  }

  private loadPageRegions(loadFromPageDto: boolean = true): void {
    this.loadPageRegionsFromContainer(this.leftContainer, loadFromPageDto);
    this.loadPageRegionsFromContainer(this.rightContainer, loadFromPageDto);
  }

  private loadPageRegionsFromContainer(container: ContainerDetail, loadFromPageDto: boolean): void {
    let currentPageRegions = '';
    if (loadFromPageDto) {
      currentPageRegions = this.getCurrentPageDto(container)?.currentPageIgnoreRegion!;
    }
    let regions = [];
    if (currentPageRegions === '') {
      regions.push(...JSON.parse(JSON.stringify(container.tmpFileRegions)));
    }
    if (currentPageRegions && currentPageRegions !== '' && currentPageRegions !== '[]') {
      regions.push(...JSON.parse(currentPageRegions));
    }
    container.tmpCurrentPageRegions = regions;
  }

  private getCurrentPageDto(container: ContainerDetail): PageDto {
    return container.pages.filter((x) => x.pageIdentity === container.currentPageIdentity)[0];
  }

  public saveRegions(): void {
    this.showRegionCanvas = false;
    const containers = [this.leftContainer, this.rightContainer];

    containers.forEach((container) => {
      if (this.compareRegions(container.fileRegions, container.tmpFileRegions)) {
        this.SaveFileRegionsAndProcessFacts(container);
      }
      if (this.compareRegions(container.currentPageRegions, container.tmpCurrentPageRegions)) {
        this.SavePageRegionsAndProcessFacts(container);
      }
    });
  }

  private compareRegions(regions: Rect[], tmpRegions: Rect[]): boolean {
    return JSON.stringify(regions) !== JSON.stringify(tmpRegions);
  }

  private SaveFileRegionsAndProcessFacts(container: ContainerDetail) {
    this.showLoader(true);
    container.fileRegions = container.tmpFileRegions;
    this.fileCompareService.saveFileRegions(container.fileId, JSON.stringify(container.fileRegions)).subscribe({
      next: () => {
        this.notificationService.success(AppConstants.SetRegionTitle, AppConstants.RegionsSavedSuccessMessage);
        this.showLoader(false);
      },
      error: (err) => {
        this.notificationService.error(AppConstants.RegionsSavedFailureMessage, err);
        this.showLoader(false);
      },
    });
    if (container.fileRegions.length > 0) {
      this.ignoreFacts(container.factList, container.fileRegions, container.id!);
    }
    this.scrollAndSelectFirstElement(container);
  }

  private SavePageRegionsAndProcessFacts(container: ContainerDetail) {
    this.showLoader(true);
    container.currentPageRegions = container.tmpCurrentPageRegions;
    const currentPageDto = this.getCurrentPageDto(container);
    if (currentPageDto) {
      currentPageDto.currentPageIgnoreRegion = JSON.stringify(container.currentPageRegions);
    }
    this.fileCompareService.savePageRegions(container.currentPageIdentity, JSON.stringify(container.currentPageRegions)).subscribe({
      next: () => {
        this.notificationService.success(AppConstants.SetRegionTitle, AppConstants.RegionsSavedSuccessMessage);
        this.showLoader(false);
      },
      error: (err) => {
        this.notificationService.error(AppConstants.RegionsSavedFailureMessage, err);
        this.showLoader(false);
      },
    });
    if (container.currentPageRegions.length > 0) {
      this.ignoreFacts(container.factList, container.currentPageRegions, container.id!);
    }
    this.scrollAndSelectFirstElement(container);
  }

  public cancelRegionsButtonClicked(): void {
    this.showRegionCanvas = false;
    this.leftContainer.tmpFileRegions = [];
    this.leftContainer.currentPageRegions = [];
    this.leftContainer.tmpCurrentPageRegions = [];
    this.rightContainer.tmpFileRegions = [];
    this.rightContainer.currentPageRegions = [];
    this.rightContainer.tmpCurrentPageRegions = [];
    setTimeout(() => {
      this.removeStyleFromElement('left-doc');
      this.removeStyleFromElement('right-doc');
      this.removeStyleFromElement('compare-page');
    });
  }

  private removeStyleFromElement(id: string): void {
    const frameEle = document.getElementById(id);
    if (frameEle) {
      frameEle.removeAttribute('style');
    }
  }

  public isContainerLocked(container: ContainerDetail): boolean {
    return this.isPageStatusLocked(container.currentPageStatus);
  }

  public anyContainerLocked(): boolean {
    return (
      this.isPageStatusLocked(this.leftContainer.currentPageStatus) ||
      (!(this.isMonolingualSearch || this.isSquareBracketSingleFile) && this.isPageStatusLocked(this.rightContainer.currentPageStatus))
    );
  }

  public areBothContainerLocked(): boolean {
    return (
      this.isPageStatusLocked(this.leftContainer.currentPageStatus) &&
      (this.isMonolingualSearch ||
        this.isFinancialCheckType ||
        this.isPageStatusLocked(this.rightContainer.currentPageStatus) ||
        this.isSquareBracketSingleFile)
    );
  }

  private areBothContainerUnLocked(): boolean {
    return (
      this.leftContainer.currentPageStatus === PageStatus.New &&
      (this.isMonolingualSearch || this.isSquareBracketSingleFile || this.rightContainer.currentPageStatus === PageStatus.New)
    );
  }

  public hasUndoHistory(container: ContainerDetail | null = null): boolean {
    if (container === null) {
      return (
        this.areBothContainerUnLocked() &&
        this.leftContainer.factListHistory.length > 0 &&
        (this.isMonolingualSearch || this.rightContainer.factListHistory.length > 0 || this.isSquareBracketSingleFile)
      );
    } else {
      return !this.isContainerLocked(container) && container.factListHistory.length > 0;
    }
  }

  public selectIgnoreRegionType(value: string, isChecked: boolean): void {
    if (value === 'compareRegion') {
      this.compareRegionControl.setValue(isChecked);
      this.ignoreRegionControl.setValue(!isChecked);
      this.isIgnoreRegionSelected = !isChecked;
    }
    if (value === 'ignoreRegion') {
      this.compareRegionControl.setValue(!isChecked);
      this.ignoreRegionControl.setValue(isChecked);
      this.isIgnoreRegionSelected = isChecked;
    }
  }

  public goToDifferentPageCount(): any {
    const differentPages = this.isSquareBracketCheckType ? this.differentSquareBracketPages : this.differentRedactedPages;
    if (differentPages.length === 0) {
      return this.dialogService.open(
        AlertDialog,
        this.isSquareBracketCheckType ? AppConstants.NoSquareBracketCountPagesAlert : AppConstants.NoRedactedDifferentCountPagesAlert
      );
    }
    const leftPageCount = differentPages.filter((obj) => +obj > this.leftContainer.currentPageNumber);
    const rightPageCount = differentPages.filter((obj) => +obj > this.rightContainer.currentPageNumber);
    if (leftPageCount.length > 0 && this.isLastPage(this.leftContainer)) {
      leftPageCount.length = 0;
    }
    if (rightPageCount.length > 0 && this.isLastPage(this.rightContainer)) {
      rightPageCount.length = 0;
    }
    if (leftPageCount.length === 0 && rightPageCount.length === 0) {
      return this.dialogService.open(
        AlertDialog,
        this.isSquareBracketCheckType
          ? AppConstants.ReachedEndOfSquareBracketCountPagesAlert
          : AppConstants.ReachedEndOfRedactedDifferentCountPagesAlert
      );
    }

    if (leftPageCount.length > 0 || rightPageCount.length > 0) {
      this.leftPageLoaded = false;
      this.rightPageLoaded = false;
      const leftRedactedPage = +leftPageCount[0] > this.leftContainer.pageCount ? this.leftContainer.pageCount : +leftPageCount[0];
      const rightRedactedPage = +rightPageCount[0] > this.rightContainer.pageCount ? this.rightContainer.pageCount : +rightPageCount[0];
      if (this.isLastPage(this.leftContainer) && rightPageCount.length > 0) {
        this.onNavigate(this.rightContainer, rightRedactedPage, true);
        return;
      }
      if (this.isLastPage(this.rightContainer) && leftPageCount.length > 0) {
        this.onNavigate(this.leftContainer, leftRedactedPage, true);
        return;
      }
      this.navigateToPage(this.leftContainer, this.rightContainer, leftRedactedPage, rightRedactedPage, this.skipDirtyCheck(), false);
    }
  }

  public goToNextGlossaryPage(): any {
    if (this.companySpecificGlossaryPages.length === 0) {
      return this.dialogService.open(AlertDialog, AppConstants.NoGlossaryPagesAlert);
    }
    const companySpecificGlossaryPageCount = this.companySpecificGlossaryPages.filter((obj) => +obj > this.leftContainer.currentPageNumber);
    if (companySpecificGlossaryPageCount.length > 0 && this.isLastPage(this.leftContainer)) {
      companySpecificGlossaryPageCount.length = 0;
    }
    if (companySpecificGlossaryPageCount.length === 0) {
      return this.dialogService.open(AlertDialog, AppConstants.ReachedEndOfCompanySpecificGlossaryPagesAlert);
    }
    if (companySpecificGlossaryPageCount.length > 0) {
      this.leftPageLoaded = false;
      this.rightPageLoaded = false;
      const companySpecificGlossaryPage =
        +companySpecificGlossaryPageCount[0] > this.leftContainer.pageCount
          ? this.leftContainer.pageCount
          : +companySpecificGlossaryPageCount[0];
      this.onNavigate(this.leftContainer, companySpecificGlossaryPage, true);
    }
  }

  public annotationClick(frameType: FrameType) {
    switch (frameType) {
      case FrameType.Left:
        this.leftAnnotationClicked = true;
        this.leftDocument = this.leftContainer.pdfFilePath;
        this.pageIdentity = this.leftContainer.currentPageIdentity;
        break;

      case FrameType.Right:
        this.rightAnnotationClicked = true;
        this.rightDocument = this.rightContainer.pdfFilePath;
        this.pageIdentity = this.rightContainer.currentPageIdentity;
        break;
    }
  }

  public closePdf(frameType: FrameType) {
    switch (frameType) {
      case FrameType.Left:
        this.leftAnnotationClicked = false;
        break;

      case FrameType.Right:
        this.rightAnnotationClicked = false;
        break;
    }
  }

  public OnChanged(container: ContainerDetail): void {
    container.isDirty = true;
  }

  public getBookmarkButtonTooltip(): string {
    return this.leftContainer.isBookmarked && this.rightContainer.isBookmarked
      ? AppConstants.RemoveBookmarkTooltip
      : AppConstants.SetBookmarkTooltip;
  }

  public getIgnoreRegionClasses(): string {
    const rowPrefix = this.showRegionCanvas ? 'row-ignoreRegion' : 'row';
    const dashSuffix = this.isMonolingualSearch || (this.isSquareBracketSingleFile && this.isSquareBracketCheckType) ? '-dash' : '';
    return `${rowPrefix}${dashSuffix}`;
  }

  public showLoader(isShow: boolean) {
    this.commonService.showSpinner.next(isShow);
  }

  public showBookmarkedIcon(): boolean {
    return (
      this.leftContainer.isBookmarked &&
      (this.isMonolingualSearch || this.isFinancialCheckType || this.rightContainer.isBookmarked || this.isSquareBracketSingleFile)
    );
  }

  private saveFinancialTableCheckPage() {
    this.isPageLocked = true;
    this.leftPdfViewer.leftPdfViewerToolbar.disablePencil();
    this.leftPdfViewer.leftPdfViewerToolbar.saveAnnotationDetails().then(() => {
      this.commonService.showSpinner.next(true);
      let exportAnnotationPromise;
      if (this.leftPdfViewer.leftPdfViewer.annotationCollection.length > 0) {
        exportAnnotationPromise = this.leftPdfViewer.leftPdfViewer.exportAnnotationsAsObject(AnnotationDataFormat.Xfdf);
        setTimeout(() => {
          this.leftPdfViewer.leftPdfViewer.download();
        }, 100);
      } else {
        let request = {
          document: this.leftDocument.split('/').at(-1),
          isAnnotationsExist: 'false',
          hasRealAnnotations: 'false',
          elementId: this.leftPdfViewer.leftPdfViewer.element.id,
        };

        exportAnnotationPromise = new Promise((resolve) => {
          this.pdfViewerService.exportAnnotationPages(request, this.pageIdentity, this.jobIdentity).subscribe(() => {
            resolve(true);
          });
        });
      }
      exportAnnotationPromise.then(() => {
        const fileSavedPromise = new Promise((resolve, reject) => {
          this.savePage(this.leftContainer, resolve, reject, false);
        });
        fileSavedPromise.then((result1) => {
          if (result1) {
            this.leftContainer.pages.filter((x) => x.pageSequence === this.leftContainer.currentPageNumber)[0].pageStatus =
              PageStatus.Completed;
            this.leftContainer.isDirty = false;

            if (this.isLastPage(this.leftContainer)) {
              this.leftContainer.currentPageStatus = PageStatus.Completed;
            } else {
              this.onNextClick(this.leftContainer);
            }
          }
          this.commonService.showSpinner.next(false);
        });
      });
    });
  }

  private resetFinancialCheckPage() {
    const resetPages: ResetPageInfo[] = [{ pageIdentity: this.leftContainer.currentPageIdentity, isCompleted: true }];
    this.fileCompareService.ResetPageDetails(this.jobIdentity, resetPages).subscribe(() => {
      this.leftContainer.pages.filter((x) => x.pageSequence === this.leftContainer.currentPageNumber)[0].pageStatus = PageStatus.New;
      this.leftContainer.currentPageStatus = PageStatus.New;
      this.isPageLocked = false;
      this.leftPdfViewer.leftPdfViewer.annotationSettings.isLock = false;
      this.fileCompareService.resetPageSubject.next(false);
    });
  }

  private isLeftContainer(container: ContainerDetail): boolean {
    return container.id === 'left-doc';
  }

  private isLeftContainerId(id: string): boolean {
    return id === 'left-doc';
  }

  public isFileNameDisabled(userType: number): boolean {
    return (
      userType === UserType['Client User'] &&
      !this.appDataService.featurePermissions.accessClientFiles &&
      !this.appDataService.user.isClientUser
    );
  }

  public onRotatePage(container1: ContainerDetail | null, container2: ContainerDetail | null, bothContainer: boolean = false) {
    if (container1 && !bothContainer) {
      this.rotatePage(container1, null);
    }
    if (container2 && !bothContainer) {
      this.rotatePage(null, container2);
    }
    if (container1 && container2) {
      this.rotatePage(container1, container2);
    }
  }

  public rotatePage(container1: ContainerDetail | null, container2: ContainerDetail | null): void {
    if (
      this.savedFactLists.length > 0 ||
      (container1 && container1.factListHistory.length > 0) ||
      (container2 && container2.factListHistory.length > 0)
    ) {
      this.rotatePageDialog = this.dialogService.open(AlertDialog, AppConstants.RotatePageConfirmDialogOptions);
      this.dialogService.confirmed(this.rotatePageDialog).subscribe((result) => {
        if (result) {
          if (container1) {
            this.rotatePageFn(container1.currentPageIdentity);
          }
          if (container2) {
            this.rotatePageFn(container2.currentPageIdentity);
          }
        }
      });
    } else {
      if (container1) {
        this.rotatePageFn(container1.currentPageIdentity);
      }
      if (container2) {
        this.rotatePageFn(container2.currentPageIdentity);
      }
    }
  }

  public rotatePageFn(pageIdentity: number) {
    this.showLoader(true);
    this.fileCompareService.rotatePage(pageIdentity).subscribe({
      next: (data: GetPageDetailResponse) => {
        const rightContainerPage: PageDto = this.rightContainer.pages.find((x) => x.pageIdentity === pageIdentity)!;
        if (rightContainerPage) {
          new Promise<boolean>((resolve, reject) => {
            this.processPage(
              this.rightContainer,
              rightContainerPage.pageSequence,
              rightContainerPage,
              data.comparePages[0].content,
              resolve,
              reject
            );
          });
        }
        const leftContainerPage: PageDto = this.leftContainer.pages.find((x) => x.pageIdentity === pageIdentity)!;
        if (leftContainerPage) {
          new Promise<boolean>((resolve, reject) => {
            this.processPage(
              this.leftContainer,
              leftContainerPage.pageSequence,
              leftContainerPage,
              data.comparePages[0].content,
              resolve,
              reject
            );
          });
        }
        this.notificationService.success('', AppConstants.PageRotatedSuccessfully);
        this.showLoader(false);
      },
      error: (error: Error | null) => {
        if (error) {
          this.notificationService.error('', AppConstants.ErrorRotatePage);
        }
      },
    });
  }
}
