import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AppConstants } from '../app.constants';

import { ContainerDetail } from '../models/container-detail.model';
import { JobInformation } from '../models/job-information.model';

import { FeatureType } from '../core/enums/feature-type.enum';
import { JobStatus } from '../core/enums/job-status.enum';

import { DialogService } from './dialog.service';
import { FileCompareService } from './file-compare.service';
import { FileStoreService } from './file-store.service';
import { NotificationService } from './notification.service';
import { MsalService } from '@azure/msal-angular';

import { JobInformationDialogComponent } from '../components/file-compare/job-info/job-information-dialog.component';
import { DataDogService } from './data-dog.service';
import { UserType } from '../core/enums/user-type.enum';
import { AppDataService } from './app-data.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public readonly showSpinner: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fileStoreService: FileStoreService,
    private fileCompareService: FileCompareService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private authService: MsalService,
    private appDataService: AppDataService
  ) {}

  public viewJobInfo(jobInfo: JobInformation): void {
    const options = {
      width: '545px',
      title: AppConstants.JobInformationTitle,
      jobInfo: jobInfo,
    };
    this.dialogService.open(JobInformationDialogComponent, options);
  }

  public viewSourcePdf(
    jobIdentity: string,
    id: number,
    typeId: number,
    leftContainer: ContainerDetail,
    rightContainer: ContainerDetail | null,
    isSquareBracketSingleFile: boolean = false,
    jobInformation:JobInformation
      ): void {
                  
    this.fileCompareService
      .GetSourcePdf(
        jobIdentity,
        id,
        jobInformation.englishFileLocation,
        leftContainer.currentPageNumber,
        jobInformation.chineseFileLocation,
        rightContainer!.currentPageNumber,
        typeId
      )
      .subscribe({
        next: (data) => {
          DataDogService.logDownloadFile(data.fileBytes, data.fileName);
          this.fileStoreService.downloadFileFn(data.fileBytes, data.fileName);
        },
        error: (error) => {
          this.notificationService.error('Error while generating Source PDF', error);
        },
      });
  }

  public waitForElement(element: ElementRef, selector: string) {
    return new Promise((resolve) => {
      if (element.nativeElement.querySelector(selector)) {
        return resolve(element.nativeElement.querySelector(selector));
      }
      const observer = new MutationObserver(() => {
        if (element.nativeElement.querySelector(selector)) {
          resolve(element.nativeElement.querySelector(selector));
          observer.disconnect();
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  public getJobStatusTooltip(jobStatus: number): string {
    if (jobStatus !== null && jobStatus !== undefined) {
      return JobStatus[jobStatus].replace(/_/gi, ' ');
    }
    return '';
  }

  public logout(): void {
    localStorage.removeItem(AppConstants.User2FAverify);
    localStorage.removeItem(AppConstants.User2FAverifyMailSent);
    localStorage.removeItem(AppConstants.User2FAIsClientUser);
    localStorage.removeItem(AppConstants.User2FAverifyFromTera);
    this.authService.logoutRedirect({
      account: this.authService.instance.getActiveAccount(),
    });
  }
  public getTokenExpInMinute(tokenExp: number): number {
    var difference = new Date(tokenExp * 1000).getTime() - new Date().getTime(); // This will give difference in milliseconds
    return Math.round(difference / 60000);
  }

  public isFileNameDisabled(userType: number): boolean{
    return (userType === UserType['Client User'] && !this.appDataService.featurePermissions.accessClientFiles && !this.appDataService.user.isClientUser);
  }
}
