import { ItemModel } from '@syncfusion/ej2-angular-splitbuttons';

import { DialogOptions } from './models/dialog-options.model';

import { SVGIcon } from '@progress/kendo-svg-icons';

export abstract class AppConstants {
  // Application common constants
  static readonly AppSettings: string = 'appSettings';

  static readonly HkTimeZone = 'Asia/Hong_Kong';

  // Dashboard Header Constants
  static readonly ApplicationTitle: string = 'ACS';
  static readonly UserRoleLabel: string = 'Dashboard';

  // Dashboard Footer Constants
  static readonly AboutApplicationLabel: string = 'About ACS';
  static readonly CopyRightsLabel: string = `TOPPAN Nexus Limited. ${new Date().getFullYear()}. All rights reserved.`;
  static readonly ToppanNexusLinkLabel: string = ' TOPPANNEXUS.COM';
  static readonly ToppanNexusWebSiteLink = 'https://www.toppannexus.com/';
  static readonly UsageReportLabel: string = 'ACS Usage Report';
  static readonly AcsUserEmailKeyName = 'ACSUserEmail';
  static readonly AcsContactUs = 'Contact Us';
  static readonly AcsTermsAndConditions = 'Terms & Conditions';
  static readonly AcsDataPrivacyNotice = 'Data Privacy Notice';

  static readonly ErrorCategoryAndSeverityLabel: string = 'Error Category and Severity';
  static readonly ErrorCategoryLabel: string = 'Error Category';
  static readonly ErrorInvalidData = (controlName: string) => `Please enter the valid ${controlName}.`;
  static readonly OthersLabel: string = 'Others';
  static readonly SeverityLabel: string = 'Severity';
  static readonly StillLoadingData: string = 'Still loading the data. please wait ...';
  static readonly SubErrorCategoryLabel: string = 'Sub Error Category';
  static readonly SubmitLabel: string = 'Submit';
  static readonly UpdateLabel: string = 'Update';
  static readonly PositiveRegion: string = 'positive';
  static readonly NegativeRegion: string = 'negative';

  static readonly annotationColors: ItemModel[] = [
    { text: 'Brown' },
    { text: 'Light purple' },
    { text: 'Light yellow' },
    { text: 'Mauve' },
    { text: 'Peach' },
    { text: 'Red' },
    { text: 'Tan' },
  ];

  static readonly ErrorCategoryData = [
    { id: 1, name: 'Accuracy' },
    { id: 2, name: 'Fluency' },
    { id: 3, name: 'Text Style' },
    { id: 4, name: 'Format' },
    { id: 5, name: 'Others' },
  ];
  static readonly SeverityData = [
    { id: 1, name: 'Serious error' },
    { id: 2, name: 'General error' },
    { id: 3, name: 'Format error' },
    { id: 4, name: 'Inconsistency' },
  ];

  static readonly AccuracySubErrorCategoryData = [
    { id: 101, name: "Company name/Person's name" },
    { id: 102, name: 'Missing Translation' },
    { id: 103, name: 'Extra Translation' },
    { id: 104, name: 'Translation Mistake' },
    { id: 105, name: 'Currency' },
    { id: 106, name: 'Others' },
  ];

  static readonly FluencySubErrorCategoryData = [
    { id: 201, name: 'Inconsistent translation' },
    { id: 202, name: 'Punctuation' },
    { id: 203, name: 'Figure' },
    { id: 204, name: 'Others' },
  ];
  static readonly TextStyleSubErrorCategoryData = [
    { id: 301, name: 'Bold' },
    { id: 302, name: 'Italic' },
    { id: 303, name: 'Underline' },
    { id: 304, name: 'Highlight' },
    { id: 305, name: 'Font style' },
    { id: 306, name: 'Font color' },
    { id: 307, name: 'Font size' },
    { id: 308, name: 'Others' },
  ];
  static readonly FormatSubErrorCategoryData = [
    { id: 401, name: 'Indentation' },
    { id: 402, name: 'Text placement in table cells' },
    { id: 403, name: 'Line spacing' },
    { id: 404, name: 'Pagination' },
    { id: 405, name: 'Others' },
  ];
  static readonly OthersSubErrorCategoryData = [
    { id: 501, name: 'Graph' },
    { id: 502, name: 'Logo' },
    { id: 503, name: 'Others' },
  ];

  static readonly OkButtonLabel: string = 'OK';
  static readonly CancelButtonLabel: string = 'Cancel';
  static readonly YesButtonLabel: string = 'Yes';
  static readonly NoButtonLabel: string = 'No';
  static readonly SetForAllPagesToolTip: string = 'Set for All Pages';
  static readonly SetForCurrentPagesToolTip: string = 'Set for Current Pages';

  static readonly AboutAcsTitle: string = 'About ACS';
  static readonly AboutAppDialogOptions: DialogOptions = {
    width: '406px',
    title: AppConstants.AboutAcsTitle,
    message: 'About ACS message',
    confirmText: AppConstants.OkButtonLabel,
    autoFocus: false,
  };
  static readonly MicrosoftGraph: string = 'https://graph.microsoft.com/v1.0/me';
  static readonly AppRoutes = {
    error_forbidden: 'forbidden',
  };
  static readonly SignalRMethods = {
    broadcastMessage: 'BroadcastMessage',
    companyUpdate: 'BroadCastCompanyMessage',
  };
  static readonly JobListTitle: string = 'Job List';
  static readonly RefreshJobListTitle: string = 'Refresh Job List';

  static readonly GlossaryListTitle: string = 'Glossary List';

  static readonly AcsAdmin = 'ACS Admin';

  static readonly SearchPlaceholderTitle: string = 'Search';

  static readonly CloseJobConfirmationTitle: string = 'Close Job';
  static readonly ExportBookmarkReportTitle: string = 'Bookmark Report';
  static readonly DownloadFile1AnnotatedPdfTitle: string = 'File 1 Annotated PDF';
  static readonly DownloadFile2AnnotatedPdfTitle: string = 'File 2 Annotated PDF';
  static readonly CloseJobConfirmationMessage: string = 'Do you want to close this job?';
  static readonly CloseCurrentPageConfirmationMessage: string = `WARNING: Changing the toggle setting from OFF to ON will<br/>clear the current page regions.<br/> Are you sure you want to continue?`;
  static readonly CloseJobConfirmationSuccessMessage: string = 'Job closed successfully.';
  static readonly RedactionReportLabel: string = 'Redaction Report';
  static readonly ExportAnnotationSummaryReportTitle: string = 'Annotation Summary Report';
  static readonly ExportAnnotationDetailsReportTitle: string = 'LQA Report';
  static readonly SquareBracketReportTitle: string = 'Square Bracket Report';
  static readonly UnsavedRegionsTitle: string = 'Unsaved Regions';
  static readonly PageRotatedSuccessfully: string = 'Page rotated successfully.';
  static readonly ErrorRotatePage: string = 'Rotate page failed.';

  static readonly StartNewComparisonJobDialogTitle: string = 'Start New Comparison';
  static readonly StartNewComparisonSuccessMessage: string = 'Job added successfully. Conversion work in progress.';
  static readonly JobNameFieldTitle: string = 'Enter Job Name';
  static readonly UploadEnglishFileLabel: string = 'Drag and drop your PDF/WORD file 1<br />OR<br />Browse';
  static readonly UploadChineseFileLabel: string = 'Drag and drop your PDF/WORD file 2<br />OR<br />Browse';
  static readonly UploadChineseFileLabelOptional: string = 'Drag and drop your PDF/WORD file 2 (Optional)<br />OR<br />Browse';
  static readonly UploadGlossaryFileLabel: string = 'Drag and drop your customized glossary EXCEL file<br />OR<br />Browse<br />(Optional)';
  static readonly ForGlossaryFeatureUploadGlossaryFileLabel: string =
    'Drag and drop your customized glossary EXCEL file<br />OR<br />Browse';
  static readonly UploadFileLabel: string = 'Drag and drop your file<br />OR<br />Browse';

  static readonly StartButtonLabel: string = 'Start';
  static readonly BrowseButtonLabel: string = 'Browse';
  static readonly ErrorInvalidFileType: string = 'File format supported: .pdf/.doc/.docx';
  static readonly ErrorInvalidFileTypeExcel: string = 'File format supported: .xls/.xlsx';

  static readonly ExcelFileType = '.xlsx';
  static readonly XlsFileType = '.xls';

  static readonly ComparePageStatus: string = 'Comparison Page Status';

  static readonly UnlockPageConfirmationTitle: string = 'Unlock Page';
  static readonly UnlockPageConfirmationMessage: string = 'Do you want to unlock this page?';
  static readonly UnlockPageConfirmationSuccessMessage: string = 'Unlocked successfully.';

  static readonly SavePageConfirmationTitle: string = 'Save Page';
  static readonly SavePageConfirmationMessage: string = 'Save changes and move to next page?';

  static readonly PageConversionTitle: string = 'Page Conversion in Progress';
  static readonly PageConversionMessage: string = 'You will be redirected automatically once it is completed.';
  static readonly PageConversionWipError: string = 'Page Conversion Work in progress';
  static readonly RotatePage: string = 'Rotate Page';
  static readonly RotatePageTitle: string = 'Rotate Page';
  static readonly RotatePageMessage: string =
    'Rotating the page will reset all accepted/rejected expressions. Are you sure you want to proceed?';

  static readonly EnglishLanguage: string = 'File 1';
  static readonly ChineseLanguage: string = 'File 2';

  static readonly AcceptedFactClassName: string = 'accepted';
  static readonly RejectedFactClassName: string = 'rejected';
  static readonly IgnoredFactClassName: string = 'ignored';
  static readonly SelectedFactClassName: string = 'selected';
  static readonly GlossaryFactClassName: string = 'glossary';
  static readonly GlossaryAcceptedFactClassName: string = 'glossary-accepted';
  static readonly GlossaryRejectedFactClassName: string = 'glossary-rejected';
  static readonly SquareBracketClassName: string = 'square-bracket';
  static readonly SquareBracket1ClassName: string = 'square-bracket1';
  static readonly SquareBracketGlossaryClassName: string = 'square-bracket-glossary';

  static readonly PdfFileExtension: string = 'pdf';

  static readonly PdfMimeType: string = 'application/pdf';
  static readonly DocMimeType: string = 'application/msword';
  static readonly DocxMimeType: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  static readonly ErrorUnauthorized: string = 'You are unauthorized to access ACS. Please login to TCC and try again.';
  static readonly ErrorSessionTimeoutTitle: string = 'Session Timeout';
  static readonly ErrorSessionTimeout: string = 'Session timeout. Please re-login.';
  static readonly ErrorTooManyRequests: string = 'You have sent too many requests to us recently. Please try again later.';

  static readonly RequestIdHeader: string = 'Request-Id';
  static readonly AuthorizationHeader: string = 'Authorization';
  static readonly PageIdentityHeader: string = 'PageIdentity';
  static readonly JobIdentityHeader: string = 'JobIdentity';
  static readonly PageSequenceHeader: string = 'PageSequence';
  static readonly FileIdentityHeader: string = 'FileIdentity';
  static readonly OtherFileIdentityHeader: string = 'OtherFileIdentity';

  static readonly JobIdLabel: string = 'Job ID';
  static readonly JobNameLabel: string = 'Job Name';
  static readonly CompanyNameLabel: string = 'Company Name';
  static readonly EnglishFileLabel: string = 'File 1';
  static readonly PageCountLabel: string = 'Count';
  static readonly ChineseFileLabel: string = 'File 2';
  static readonly JobStatusLabel: string = 'Status';
  static readonly GlossaryLabel: string = 'Glossary File';
  static readonly CreationDateLabel: string = 'Creation Date';
  static readonly ActionLabel: string = 'Action';
  static readonly JobTypeNameLabel: string = 'Type';
  static readonly ComparePageLabel: string = 'Open Compare Page';

  static readonly StartNewComparisonLabel: string = 'Start New Comparison';
  static readonly GlossaryManagementLabel: string = 'Glossary Management';
  static readonly NoDataFound: string = 'No data available';

  static readonly NoOfHighLightsTooltip: string = 'No. of highlights';
  static readonly AcceptTooltip: string = 'Accept';
  static readonly ErrorTooltip: string = 'Error';
  static readonly AcceptAndMoveTooltip: string = 'Accept and Move Next';
  static readonly DashboardTooltip: string = 'Dashboard';
  static readonly SwapTooltip: string = 'Swap';
  static readonly ViewSourcePdfTooltip: string = 'View Source PDF';
  static readonly AnnotatePdfTooltip: string = 'Annotate PDF';
  static readonly SaveTooltip: string = 'Save';
  static readonly SetBookmarkTooltip: string = 'Set Bookmark';
  static readonly RemoveBookmarkTooltip: string = 'Remove Bookmark';
  static readonly ClearAllRegions: string = 'Clear all Regions';
  static readonly RegionsSavedSuccessMessage: string = 'Regions saved successfully';
  static readonly RegionsSavedFailureMessage: string = 'Error saving Regions';
  static readonly SetRegionTitle: string = 'Set Region';
  static readonly IgnoreRegionLabel: string = 'Ignore Region';
  static readonly InactiveSession: string = 'InactiveSession';
  static readonly CompareRegionLabel: string = 'Compare Region';
  static readonly SynchronousScrollingLabel: string = 'Synchronous Scrolling';
  static readonly OtpRequiredValue: string = 'Please enter OTP.';
  static readonly OtpInvalid: string = 'Invalid OTP entered.';
  static readonly User2FAverify: string = 'User2FAverify';
  static readonly User2FAverifyFromTera: string = 'User2FAverifyFromTera';
  static readonly User2FAverifyMailSent: string = 'User2FAverifyMailSent';
  static readonly User2FAIsClientUser: string = 'User2FAIsClientUser';
  static readonly ReadAcceptClientUser: string = 'ReadAcceptClientUser';

  static readonly JobInformationTitle: string = 'Job Information';
  static readonly GoToPage: string = 'Go to page';
  static readonly UndoLastAction: string = 'Undo';
  static readonly EnglishFileNameLabel: string = 'File 1';
  static readonly ChineseFileNameLabel: string = 'File 2';
  static readonly LockedPagesLabel: string = 'Locked Pages';
  static readonly EnglishPageCount: string = 'File 1 Page Count';
  static readonly ChinesePageCount: string = 'File 2 Page Count';
  static readonly ErrorJobLockedTitle: string = 'Job Locked';
  static readonly ErrorJobLockedMessage: string = 'The Job has been Locked. Tera Task is Void or Completed';

  static readonly FilePageCountLabel: string = 'Page Count';

  static readonly ErrorRequiredValue: string = 'You must enter a value';
  static readonly ErrorNotValidPageNumber: string = 'Not a valid page number';

  static readonly ShowOldJobsLabel: string = 'Show old jobs';

  static readonly ErrorConnectingServer: string = 'Error connecting server. Try refreshing the page.';
  static readonly UnexpectedError: string = 'An unexpected error has occurred. Try refreshing the page.';
  static readonly EmailVerificationSuccessMessage: string = 'OTP is verified successfully.';
  static readonly EmailSentSuccessMessage: string = 'OTP is sent successfully.';
  static readonly ErrorInSendingEmail: string = 'Error in sending OTP.';
  static readonly AcceptLabel: string = 'Accept';
  static readonly AcceptTermsAndConditionsAndDataPrivacyNotice: string =
    'Yes, I accept the Terms and Conditions and the Data Privacy Notice.';

  static readonly UnlockPageConfirmDialogOptions: DialogOptions = {
    width: null,
    title: AppConstants.UnlockPageConfirmationTitle,
    message: AppConstants.UnlockPageConfirmationMessage,
    confirmText: AppConstants.YesButtonLabel,
    cancelText: AppConstants.NoButtonLabel,
  };
  static readonly PageConversionConfirmDialogOptions: DialogOptions = {
    width: '375px',
    title: AppConstants.PageConversionTitle,
    message: AppConstants.PageConversionMessage,
    hideOkButton: true,
    loadSpinner: true,
    disableClose: true,
  };
  static readonly RotatePageConfirmDialogOptions: DialogOptions = {
    width: '405px',
    title: AppConstants.RotatePageTitle,
    message: AppConstants.RotatePageMessage,
    confirmText: AppConstants.YesButtonLabel,
    cancelText: AppConstants.NoButtonLabel,
  };
  static readonly SavePageOptions: DialogOptions = {
    title: AppConstants.SavePageConfirmationTitle,
    message: AppConstants.SavePageConfirmationMessage,
    confirmText: AppConstants.OkButtonLabel,
    cancelText: AppConstants.CancelButtonLabel,
  };
  static readonly UserAccessRevoked: string = 'AcsUserAccessRevoked';
  static readonly ShowAccessRevokedAlert: string = 'AcsShowAccessRevokedAlert';
  static readonly ErrorRevokeUserAccess: string =
    'It seems that your access has been changed. To ensure that you have the correct permissions, you will need to sign in again. Click OK to continue.';
  static readonly UserRevokeDialogOptions = (msg: string): DialogOptions => {
    return {
      width: '482px',
      message: msg,
      confirmText: AppConstants.OkButtonLabel,
      icon: 'error_outline',
      iconClass: 'warning-dlg-ico',
      contentClass: 'text-center',
      disableClose: true,
      closeOnEscapeKey: false,
    };
  };
  static readonly UnSavedPageDialogMessageMonoLingual = `WARNING:Page change(s) are not saved.
      Press 'Cancel' to stay and save this page, or press 'OK' to proceed.`;
  static readonly UnSavedPageDialogMessageEnglish = `WARNING: File 1 page change(s) are not saved.
      Press 'Cancel' to stay and save this page, or press 'OK' to proceed.`;
  static readonly UnSavedPageDialogMessageChinese = `WARNING: File 2 page change(s) are not saved.
      Press 'Cancel' to stay and save this page, or press 'OK' to proceed.`;
  static readonly UnSavedPageDialogMessageBoth = `WARNING: Both file pages are not saved.
      Press 'Cancel' to stay and save this page, or press 'OK' to proceed.`;
  static readonly UnSavedPageOptions: DialogOptions = {
    width: '400px',
    title: 'Unsaved Changes',
    message: AppConstants.UnSavedPageDialogMessageBoth,
    confirmText: AppConstants.OkButtonLabel,
    cancelText: AppConstants.CancelButtonLabel,
  };
  static readonly CloseJobConfirmDialogOptions: DialogOptions = {
    title: AppConstants.CloseJobConfirmationTitle,
    message: AppConstants.CloseJobConfirmationMessage,
    confirmText: AppConstants.YesButtonLabel,
    cancelText: AppConstants.NoButtonLabel,
  };

  static readonly CloseCurrentPageConfirmDialogOptions: DialogOptions = {
    width: '476px',
    title: AppConstants.UnsavedRegionsTitle,
    htmlContent: AppConstants.CloseCurrentPageConfirmationMessage,
    confirmText: AppConstants.YesButtonLabel,
    cancelText: AppConstants.NoButtonLabel,
  };

  static readonly NotApplicable: string = 'N/A';

  static readonly ACSToken: string = 'ACS.Token';
  static readonly RedactionReport: string = '_Redaction_Report.xlsx';
  static readonly SquareBracketReport: string = '_Square_Bracket_Report.xlsx';

  static readonly FeatureType = [
    { Id: 1, TypeName: 'Call Figure' },
    { Id: 2, TypeName: 'Call Redaction' },
    { Id: 4, TypeName: 'Chinese Characters Conversion Check' },
    { Id: 3, TypeName: 'Glossary Consistency Check' },
    //{ Id: 5, TypeName: 'Review' },
    { Id: 6, TypeName: 'Financial Table Check' },
    { Id: 7, TypeName: 'Square Bracket Check' },
  ];

  static readonly ExternalUserFeatureType = [
    { Id: 1, TypeName: 'Call Figure' },
    { Id: 3, TypeName: 'Glossary Consistency Check' },
  ];

  static readonly CallRedactionSuccessMessage: string = 'Job added successfully.<br />Call Redaction report is ready.';
  static readonly CallRedactionFailureMessage: string = 'Unsuccessful upload. Please try again.';

  static readonly ChineseCharactersConversionCheck: string = 'Chinese Characters Conversion Check';
  static readonly ChineseCharactersConversionCheckSuccessMessage: string = 'Job added successfully.';

  static readonly SquareBracketCheck: string = 'Square Bracket Check';
  static readonly SquareBracketCheckSuccessMessage: string = 'Job added successfully.';

  static readonly FinancialTableCheckTitle: string = 'Financial Table Check';
  static readonly GlossaryConsistencyTitle: string = 'Glossary Consistency Check';
  static readonly JobAddedSuccessMessage: string = 'Job added successfully.';
  static readonly ErrorFileDownload: string = 'File download failed.';

  static readonly ReviewTitle: string = 'Review';
  static readonly ReviewSuccessMessage: string = 'Job added successfully.';

  static readonly InvalidFilePdfDoc: string = 'Please select a PDF/WORD file.';
  static readonly InvalidGlossaryFile: string = 'Please select a Glossary File.';

  static readonly JobListLabel: string = 'Job List';

  static readonly UploadGlossaryLabel: string = 'Upload Glossary';
  static readonly RefreshGlossaryListTitle: string = 'Refresh Glossary List';
  static readonly BackArrowTitle: string = 'Back';
  static readonly GlossaryIdLabel: string = 'Glossary ID';
  static readonly GlossaryNameLabel: string = 'Glossary Name';
  static readonly FeatureTypeLabel: string = 'Feature Type';
  static readonly StatusLabel: string = 'Status';
  static readonly FileNameLabel: string = 'File';
  static readonly GlossaryFileNameLabel: string = 'File Name';
  static readonly DeleteGlossaryLabel: string = 'Delete Glossary';
  static readonly ErrorFetchingGlossary: string = 'Error fetching glossary list.';

  static readonly ErrorCreateGlossary: string = 'Error while create glossary.';
  static readonly SuccessCreateGlossary: string = 'Glossary uploaded successfully.';
  static readonly ErrorRequiredGlossaryName: string = 'Please enter the glossary name.';
  static readonly ErrorRequiredFeatureType: string = 'Please select the type.';
  static readonly UploadGlossaryTitle: string = 'Upload Glossary';
  static readonly UploadGlossaryAllowedFileTypes: string = '.xls,.xlsx';

  static readonly DeleteGlossaryConfirmationTitle: string = 'Delete Glossary';
  static readonly DeleteGlossaryConfirmationMessage: string = 'Are you sure to delete this glossary?';
  static readonly SuccessDeleteGlossary: string = 'Glossary deleted successfully.';
  static readonly ErrorDeleteGlossary: string = 'Error while delete glossary.';

  static readonly DeleteGlossaryConfirmDialogOptions: DialogOptions = {
    title: AppConstants.DeleteGlossaryConfirmationTitle,
    message: AppConstants.DeleteGlossaryConfirmationMessage,
    confirmText: AppConstants.YesButtonLabel,
    cancelText: AppConstants.NoButtonLabel,
  };

  static readonly UserNameRequiredValue: string = 'Please enter your User Name.';
  static readonly ErrorEmailMaximumLength: string = 'Maximum length is 50.';
  static readonly UserNameInvalid: string = 'User Name is invalid.';
  static readonly MinLengthValidationError = (minlength: number) => `Please enter more than ${minlength} characters.`;
  static readonly MaxLengthValidationError = (maxlength: number) => `Please enter less than ${maxlength} characters.`;
  static readonly ErrorDropDownSelectRequired = (controlName: string) => `Please select the ${controlName}.`;
  static readonly ErrorTextBoxRequired = (controlName: string) => `Please enter the ${controlName}.`;

  static readonly ErrorFetchingTccUsersTeams: string = 'Error fetching TccUsersTeams.';
  static readonly ErrorFetchingUserManagement: string = 'Error fetching users.';
  static readonly ToppanMerrillCompanyId: string = 'DN9LE3QEZv'; // =1 changed into hashid
  static readonly DateRangeLabel: string = 'Date & Time';
  static readonly DateFormat: string = 'MM/DD/YYYY HH:mm';
  static readonly SuccessCreateReport: string = 'Report downloaded successfully.';
  static readonly ErrorCreateReport: string = 'Error while report creating.';
  static readonly DateFormatForFileName: string = 'YYYYMMDD_HHmm';
  static readonly AccessDeniedLabel: string = 'Access denied';
  static readonly ErrorAccessDenied: string = 'Access denied: Please contact ACS Admin.';
  static readonly LoginNameLabel: string = 'Login Name';
  static readonly SelectFeatureTypeLabel: string = 'Feature Type';
  static readonly RoleLabel: string = 'Role';
  static readonly SelectGlossaryLabel: string = 'Glossary';
  static readonly SelectAnnotationLabel: string = 'Annotation';
  static readonly SelectAnnotationSideLabel: string = 'Annotation Side';
  static readonly SelectCompanyNameLabel: string = 'Company Name';
  static readonly SelectAllLabel: string = 'Select All';
  static readonly OkLabel: string = 'OK';
  static readonly CancelLabel: string = 'Cancel';
  static readonly UserNameLabel: string = 'User Name';
  static readonly AcsTeamLabel: string = 'Team';
  static readonly TeamNameLabel: string = 'Dashboard';
  static readonly NoUserFound: string = 'No User Name found';
  static readonly NoGlossaryFound: string = 'No Glossary found';
  static readonly NoUserRoleFound: string = 'No Dashboard found';
  static readonly NoAcsTeamFound: string = 'No Team found';
  static readonly NoCompanyNameFound: string = 'No Company found';

  static readonly GlossaryDefaultOption: string = 'Not Applicable';
  static readonly GlossaryFileLabel: string = 'Glossary';
  static readonly ErrorRequiredGlossary: string = 'Please select Glossary.';
  static readonly ErrorRequiredMinCharJobName = 'Job Name must be between 3 and 50 characters long.';

  static readonly RedactedDifferentCountPages: string = 'Shortcut to page with different redacted count';
  static readonly RedactedDifferentCountDialogTitle: string = 'Redacted Different Count Pages';
  static readonly NoRedactedDifferentCountPagesAlert: DialogOptions = {
    title: AppConstants.RedactedDifferentCountDialogTitle,
    message: 'No pages with different redacted counts found.',
    confirmText: AppConstants.OkButtonLabel,
  };
  static readonly ReachedEndOfRedactedDifferentCountPagesAlert: DialogOptions = {
    title: AppConstants.RedactedDifferentCountDialogTitle,
    message: 'Reach the end of pages with different redacted counts.',
    confirmText: AppConstants.OkButtonLabel,
  };

  static readonly SquareBracketCountPages: string = 'Shortcut to next page contains square bracket';
  static readonly SquareBracketCountDialogTitle: string = 'Square Bracket Count Pages';
  static readonly NoSquareBracketCountPagesAlert: DialogOptions = {
    title: AppConstants.SquareBracketCountDialogTitle,
    message: 'No Square brackets found in this Job.',
    confirmText: AppConstants.OkButtonLabel,
  };
  static readonly ReachedEndOfSquareBracketCountPagesAlert: DialogOptions = {
    title: AppConstants.SquareBracketCountDialogTitle,
    message: 'Reach the end of pages with Square Bracket Check.',
    confirmText: AppConstants.OkButtonLabel,
  };

  static readonly AllFeatureTypes: string = 'All';

  static readonly CompanySpecificGlossaryPagesTitle: string = 'Shortcut to Specific Chinese glossaries page';
  static readonly CompanySpecificGlossaryPagesDialogTitle: string = 'Specific Chinese glossaries';
  static readonly NoGlossaryPagesAlert: DialogOptions = {
    title: AppConstants.CompanySpecificGlossaryPagesDialogTitle,
    message: 'No Specific Chinese glossaries found.',
    confirmText: AppConstants.OkButtonLabel,
  };

  static readonly ReachedEndOfCompanySpecificGlossaryPagesAlert: DialogOptions = {
    title: AppConstants.CompanySpecificGlossaryPagesDialogTitle,
    message: 'Reached end of Chinese Characters Conversion Check.',
    confirmText: AppConstants.OkButtonLabel,
  };

  static readonly emailIcon: SVGIcon = {
    name: 'mail',
    content: `<path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/>`,
    viewBox: '0 -960 960 960',
  };

  static readonly phoneIcon: SVGIcon = {
    name: 'call',
    content: `<path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"/>`,
    viewBox: '0 -960 960 960',
  };

  /*****************************************/
  //Datadog changes
  /*****************************************/
  static readonly AddJobText: string = 'Create Job';
  static readonly CancelAddJobText: string = 'Cancel Job Creation';
  static readonly JobErrorText: string = 'Job Error';
  static readonly JobId: string = 'jobId';
  static readonly GlossaryId: string = 'glossaryId';

  /*****************************************/
  //Datadog changes
  /*****************************************/
}
