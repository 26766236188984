import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { AppConstants } from 'src/app/app.constants';

import { Rect } from 'src/app/models/rect.model';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.css'],
})
export class CanvasComponent implements AfterViewInit {
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;

  @Input() width!: number;
  @Input() height!: number;
  @Input() regions!: Rect[];
  @Input() toolbarPlacement!: number;
  @Input() isIgnoreRegionSelected!: boolean;

  public readonly appConstants: typeof AppConstants = AppConstants;

  private canvas!: HTMLCanvasElement;
  private context!: CanvasRenderingContext2D | null;
  private lineOffset = 4;
  private anchrSize = 5;

  private tmpBox: Rect | null = null;

  private mousedown = false;
  public clickedArea = { box: -1, pos: 'o' };
  private x1 = -1;
  private y1 = -1;
  private x2 = -1;
  private y2 = -1;
  private selectedBoxId = -1;

  ngAfterViewInit() {
    this.canvas = this.canvasRef.nativeElement as HTMLCanvasElement;
    this.context = this.canvas.getContext('2d');
    // set width and height of canvas
    this.canvas.width = this.width;
    this.canvas.height = this.height;

    // load existing rects
    if (this.regions.length > 0) {
      this.redraw();
    }

    this.canvas.onmousedown = (e) => {
      this.mousedown = true;
      this.clickedArea = this.findCurrentArea(e.offsetX, e.offsetY);
      this.x1 = e.offsetX;
      this.y1 = e.offsetY;
      this.x2 = e.offsetX;
      this.y2 = e.offsetY;
      if (this.clickedArea.box !== -1) {
        this.selectedBoxId = this.clickedArea.box;
      } else {
        this.selectedBoxId = -1;
      }
      if (this.regions.length > 0) {
        this.redraw();
      }
    };
    this.canvas.onmouseup = () => {
      if (this.clickedArea.box === -1 && this.tmpBox != null) {
        this.regions.push(this.tmpBox);
      } else if (this.clickedArea.box !== -1) {
        const selectedBox = this.regions[this.clickedArea.box];
        if (selectedBox.x1 > selectedBox.x2) {
          const previousX1 = selectedBox.x1;
          selectedBox.x1 = selectedBox.x2;
          selectedBox.x2 = previousX1;
        }
        if (selectedBox.y1 > selectedBox.y2) {
          const previousY1 = selectedBox.y1;
          selectedBox.y1 = selectedBox.y2;
          selectedBox.y2 = previousY1;
        }
      }
      this.clickedArea = { box: -1, pos: 'o' };
      this.tmpBox = null;
      this.mousedown = false;
    };
    this.canvas.onmouseout = () => {
      if (this.clickedArea.box !== -1) {
        const selectedBox = this.regions[this.clickedArea.box];
        if (selectedBox.x1 > selectedBox.x2) {
          const previousX1 = selectedBox.x1;
          selectedBox.x1 = selectedBox.x2;
          selectedBox.x2 = previousX1;
        }
        if (selectedBox.y1 > selectedBox.y2) {
          const previousY1 = selectedBox.y1;
          selectedBox.y1 = selectedBox.y2;
          selectedBox.y2 = previousY1;
        }
      }
      this.mousedown = false;
      this.clickedArea = { box: -1, pos: 'o' };
      this.tmpBox = null;
      this.selectedBoxId = -1;
      if (this.regions.length > 0) {
        this.redraw();
      }
    };
    this.canvas.onmousemove = (e) => {
      if (this.mousedown && this.clickedArea.box === -1) {
        this.x2 = e.offsetX;
        this.y2 = e.offsetY;
        this.redraw();
      } else if (this.mousedown && this.clickedArea.box !== -1) {
        this.x2 = e.offsetX;
        this.y2 = e.offsetY;
        const xOffset = this.x2 - this.x1;
        const yOffset = this.y2 - this.y1;
        this.x1 = this.x2;
        this.y1 = this.y2;

        if (
          this.clickedArea.pos === 'i' ||
          this.clickedArea.pos === 'tl' ||
          this.clickedArea.pos === 'l' ||
          this.clickedArea.pos === 'bl'
        ) {
          this.regions[this.clickedArea.box].x1 += xOffset;
        }
        if (
          this.clickedArea.pos === 'i' ||
          this.clickedArea.pos === 'tl' ||
          this.clickedArea.pos === 't' ||
          this.clickedArea.pos === 'tr'
        ) {
          this.regions[this.clickedArea.box].y1 += yOffset;
        }
        if (
          this.clickedArea.pos === 'i' ||
          this.clickedArea.pos === 'tr' ||
          this.clickedArea.pos === 'r' ||
          this.clickedArea.pos === 'br'
        ) {
          this.regions[this.clickedArea.box].x2 += xOffset;
        }
        if (
          this.clickedArea.pos === 'i' ||
          this.clickedArea.pos === 'bl' ||
          this.clickedArea.pos === 'b' ||
          this.clickedArea.pos === 'br'
        ) {
          this.regions[this.clickedArea.box].y2 += yOffset;
        }
        this.redraw();
      }
    };
  }

  public getPositionClass(): string {
    return this.toolbarPlacement === 0 ? 'toolbarPositionLeft' : 'toolbarPositionRight';
  }

  public onClearAllClick(): void {
    this.regions.length = 0;
    this.context!.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  public redrawFn(): void {
    if (this.context) {
      this.context!.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
    this.x1 = -1;
    this.x2 = -1;
    this.y1 = -1;
    this.y2 = -1;
    this.redraw();
  }

  private redraw(): void {
    if (!this.context) {
      return;
    }
    this.context!.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context!.beginPath();
    this.context!.globalCompositeOperation = 'destination-over';

    this.regions.forEach((box: Rect, index: number) => {
      this.drawBoxOn(box, this.context!, index === this.selectedBoxId);
    });

    if (this.clickedArea.box === -1) {
      this.tmpBox = this.newBox(this.x1, this.y1, this.x2, this.y2);
      if (this.tmpBox != null) {
        this.drawBoxOn(this.tmpBox, this.context!);
      }
    }
  }

  private findCurrentArea(x: number, y: number) {
    for (let i = 0; i < this.regions.length; i++) {
      const box = this.regions[i];
      const xCenter = box.x1 + (box.x2 - box.x1) / 2;
      const yCenter = box.y1 + (box.y2 - box.y1) / 2;
      if (box.x1 - this.lineOffset < x && x < box.x1 + this.lineOffset) {
        if (box.y1 - this.lineOffset < y && y < box.y1 + this.lineOffset) {
          return { box: i, pos: 'tl' };
        } else if (box.y2 - this.lineOffset < y && y < box.y2 + this.lineOffset) {
          return { box: i, pos: 'bl' };
        } else if (yCenter - this.lineOffset < y && y < yCenter + this.lineOffset) {
          return { box: i, pos: 'l' };
        }
      } else if (box.x2 - this.lineOffset < x && x < box.x2 + this.lineOffset) {
        if (box.y1 - this.lineOffset < y && y < box.y1 + this.lineOffset) {
          return { box: i, pos: 'tr' };
        } else if (box.y2 - this.lineOffset < y && y < box.y2 + this.lineOffset) {
          return { box: i, pos: 'br' };
        } else if (yCenter - this.lineOffset < y && y < yCenter + this.lineOffset) {
          return { box: i, pos: 'r' };
        }
      } else if (xCenter - this.lineOffset < x && x < xCenter + this.lineOffset) {
        if (box.y1 - this.lineOffset < y && y < box.y1 + this.lineOffset) {
          return { box: i, pos: 't' };
        } else if (box.y2 - this.lineOffset < y && y < box.y2 + this.lineOffset) {
          return { box: i, pos: 'b' };
        } else if (box.y1 - this.lineOffset < y && y < box.y2 + this.lineOffset) {
          return { box: i, pos: 'i' };
        }
      } else if (box.x1 - this.lineOffset < x && x < box.x2 + this.lineOffset) {
        if (box.y1 - this.lineOffset < y && y < box.y2 + this.lineOffset) {
          return { box: i, pos: 'i' };
        }
      }
    }
    return { box: -1, pos: 'o' };
  }

  private newBox(x1: number, y1: number, x2: number, y2: number): Rect | null {
    const boxX1 = x1 < x2 ? x1 : x2;
    const boxY1 = y1 < y2 ? y1 : y2;
    const boxX2 = x1 > x2 ? x1 : x2;
    const boxY2 = y1 > y2 ? y1 : y2;

    if (boxX2 - boxX1 > this.lineOffset * 2 && boxY2 - boxY1 > this.lineOffset * 2) {
      return {
        x1: boxX1,
        y1: boxY1,
        x2: boxX2,
        y2: boxY2,
        isIgnore: this.isIgnoreRegionSelected,
      };
    } else {
      return null;
    }
  }

  private drawBoxOn(rect: Rect, context: CanvasRenderingContext2D, isSelected: boolean = false): void {
    const rectLineWidth = 2;
    let rectColor: string;
    let selectedRectColor: string;
    let rectTitle: string;

    const xCenter = rect.x1 + (rect.x2 - rect.x1) / 2;
    const yCenter = rect.y1 + (rect.y2 - rect.y1) / 2;

    if (rect.isIgnore === undefined || rect.isIgnore) {
      rectColor = '#909090';
      selectedRectColor = '#964B00';
      rectTitle = AppConstants.IgnoreRegionLabel;
    } else {
      rectColor = '#00BFFF';
      selectedRectColor = '#0086b3';
      rectTitle = AppConstants.CompareRegionLabel;
    }

    // rect title
    context.fillStyle = '#FFF';
    context.font = 'bold 16px Arial';
    const rectTitleWidth = context.measureText(rectTitle).width + 5;
    context.fillText(rectTitle, xCenter - rectTitleWidth / 2, rect.y1 + 5);
    context.fillStyle = selectedRectColor;
    context.fillRect(xCenter - rectTitleWidth / 2 - 2.5, rect.y1 - 9, rectTitleWidth, 18);
    context.strokeStyle = rectColor;

    context.fillStyle = isSelected ? selectedRectColor : rectColor;

    context.fillRect(rect.x1 - this.anchrSize, rect.y1 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(rect.x1 - this.anchrSize, yCenter - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(rect.x1 - this.anchrSize, rect.y2 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(xCenter - this.anchrSize, rect.y2 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(rect.x2 - this.anchrSize, rect.y1 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(rect.x2 - this.anchrSize, yCenter - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);
    context.fillRect(rect.x2 - this.anchrSize, rect.y2 - this.anchrSize, 2 * this.anchrSize, 2 * this.anchrSize);

    context.rect(rect.x1, rect.y1, rect.x2 - rect.x1, rect.y2 - rect.y1);
    context.lineWidth = rectLineWidth;
    context.stroke();
  }

  public deleteSelectedRegion($event: any): void {
    if (this.selectedBoxId >= 0) {
      this.regions.splice(this.selectedBoxId, 1);
      this.selectedBoxId = -1;
      this.redraw();
    }
    $event.stopPropagation();
  }
}
